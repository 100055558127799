import { css, CSSObject, SimpleInterpolation } from 'styled-components'

// type Screens = 'desktop' | 'tablet' | 'mobile'

export type Breakpoint<T = number> = {
  minWidth?: T
  maxWidth?: T
}

export const defaultBreakpoints: Record<string, Breakpoint> = {
  mobile: {
    maxWidth: 639,
  },
  tablet: {
    minWidth: 640,
    maxWidth: 767,
  },
  laptop: {
    minWidth: 768,
    maxWidth: 1023,
  },
  desktop: {
    minWidth: 1024,
  },
  small: {
    minWidth: 640,
  },
  medium: {
    minWidth: 768,
  },
  large: {
    minWidth: 1024,
  },
  extraLarge: {
    minWidth: 1280,
  },
  viewport3: {
    minWidth: 320,
  },
  viewport4: {
    minWidth: 480,
  },
  viewport7: {
    minWidth: 768,
  },
  viewport9: {
    minWidth: 992,
  },
  viewport10: {
    minWidth: 1024,
  },
  viewport12: {
    minWidth: 1280,
  },
  viewport14: {
    minWidth: 1440,
  },
  viewport16: {
    minWidth: 1680,
  },
}

type BreakpointParam<T> =
  | T
  | (Breakpoint & {
      unit?: 'px' | 'em' | 'rem'
      ratio?: number
    })

const mqBuilder = ({ minWidth, maxWidth }: Breakpoint<string>) => {
  const sizes = [
    minWidth && `(min-width: ${minWidth})`,
    maxWidth && `(max-width: ${maxWidth})`,
  ].filter(Boolean)

  return `@media ${sizes.join(' and ')}`
}

export const createMediaQuery = <T extends Record<string, Breakpoint>>(configs?: T) => {
  const breakpoints = {
    ...defaultBreakpoints,
    ...configs,
  } as Record<keyof T, Breakpoint>
  return (breakpoint: BreakpointParam<keyof T>) => (
    ...args: [TemplateStringsArray | CSSObject, ...Array<SimpleInterpolation>]
  ) => {
    if (typeof breakpoint === 'object') {
      const { minWidth, maxWidth, unit, ratio } = {
        unit: 'px',
        ratio: 1,
        ...breakpoint,
      }

      return css`
        ${mqBuilder({
          minWidth: (minWidth && `${minWidth / ratio}${unit}`) || undefined,
          maxWidth: (maxWidth && `${maxWidth / ratio}${unit}`) || undefined,
        })} {
          ${css(...args)}
        }
      `
    }
    if (breakpoints[breakpoint]) {
      const { minWidth, maxWidth } = breakpoints[breakpoint] || {}
      return css`
        ${mqBuilder({
          minWidth: (minWidth && `${minWidth}px`) || undefined,
          maxWidth: (maxWidth && `${maxWidth}px`) || undefined,
        })} {
          ${css(...args)}
        }
      `
    }
  }
}

export const mediaQuery = createMediaQuery()
