import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

const SVG = styled('svg')`
  cursor: pointer;
`

type Props = {
  isBlack?: boolean
  onClick?(): void
}

export const Logo: React.FC<Props> = ({ isBlack = false, onClick }) => {
  const { linear } = useSpring({
    linear: isBlack ? 1 : 0,
    config: { mass: 10, tension: 550, friction: 140 },
  })
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="148.667"
      height="24"
      viewBox="0 0 148.667 24"
      onClick={onClick}
    >
      <animated.path
        d="M5.274,2.537c.289,3.715,2.908,6.7,6.6,6.7,2.613,0,4.214-1.074,6.01-2.752h0l3.2,3.323A11.554,11.554,0,0,1,11.713,14,11.668,11.668,0,0,1,0,2.537H5.274ZM37.806-10v4.724a6.917,6.917,0,0,0-6.2,7.2h0V1.99a7,7,0,0,0,6.916,7.3,6.831,6.831,0,0,0,6.823-6.62H50.61A11.9,11.9,0,0,1,38.462,14a11.8,11.8,0,0,1-12.1-11.944h0V1.99A11.88,11.88,0,0,1,37.806-10Zm28.486.358A8.957,8.957,0,0,1,72.928-7.28,7.4,7.4,0,0,1,74.88-1.958h0v.066A7.222,7.222,0,0,1,70,5.326h0l5.563,8.316H69.708l-4.88-7.451H63.394l-7.512-.03v-15.8Zm34.928,0V13.642H95.948V-9.642Zm-15.829,0,3.528,4.808V3.207L85.711-1.16v14.8H80.836V-9.642ZM123.713,8.985v4.657H106.492V8.985ZM139.406-9.642a8.952,8.952,0,0,1,6.626,2.361,7.4,7.4,0,0,1,1.952,5.322h0v.067a7.223,7.223,0,0,1-4.88,7.218h0l5.563,8.316h-5.856l-4.88-7.451H134v7.451h-5.01v-12H134v.023h5.075c2.44,0,3.839-1.33,3.839-3.293h0v-.067c0-2.062-1.321-3.185-3.5-3.314h0Zm-17.1,9.313V4.328H112.115V-.328ZM65.869-5.018H60.892V1.667h5.075c2.44,0,3.839-1.33,3.839-3.293h0v-.066c0-2.2-1.5-3.326-3.936-3.326ZM11.8-10a11.69,11.69,0,0,1,8.938,3.5h0L17.562-2.836a8.362,8.362,0,0,0-5.8-2.561,6.514,6.514,0,0,0-1.573.2h0V-9.9A13.193,13.193,0,0,1,11.8-10Zm111.916.358v4.657H106.492V-9.642Z"
        transform="translate(0 10)"
        fill={linear.interpolate({ range: [1, 0], output: ['black', 'white'] })}
        fillRule="evenodd"
      />
    </SVG>
  )
}
