import React from 'react'
import 'styled-components/macro'
import { ScrollElement } from '../ScrollElement'
import { AppDev } from './AppDev'
import { Enterprise } from './Enterprise'
import { ServiceBackground } from './ServiceBackground'
import { UIUX } from './UIUX'
import { WebDev } from './WebDev'

type Props = {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const Service: React.FC<Props> = ({ step = 0, setStep }) => {
  return (
    <ScrollElement
      page={4}
      onScroll={e => {
        const top = (e.target as HTMLDivElement).scrollTop
        setStep(Math.round((top / window.innerHeight) * 100) / 100)
      }}
    >
      <Enterprise step={step + 1} />
      <AppDev step={step + 1} />
      <WebDev step={step + 1} />
      <UIUX step={step + 1} />
      <ServiceBackground step={step + 1} />
    </ScrollElement>
  )
}
