import React from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { ContactForm } from '../ContactForm'
import { Hello } from './Hello'
import { mediaQuery } from '../../utils/styled'
import { useViewport, ViewportKeys } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

const Container = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  background: black;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  padding: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ShallWeChat = styled(animated.div)`
  position: absolute;
  & > div {
    &:first-child {
      font-weight: 300;
      font-size: 24px;
      letter-spacing: 0.01em;
      line-height: 40px;
      color: #fff;
      margin-bottom: 18px;
    }
    &:last-child {
      font-weight: bold;
      font-size: 32px;
      letter-spacing: 0.01em;
      line-height: 34px;
      color: #fff;
      width: 300px;
    }
  }
  ${mediaQuery('viewport7')`
    top: 110px;
    left: 150px;
  `}
  ${mediaQuery('viewport10')`
    left: 200px;
    top: 150px;
  `}
  ${mediaQuery('viewport12')`
    left: 50px;
    top: unset;
    bottom: 50px;
  `}
  ${mediaQuery('viewport16')`
    left: 75px;
    bottom: 75px;
  `}
`

type Props = {
  step: number
}

export const Contact: React.FC<Props> = ({ step = 6 }) => {
  const { viewport, innerHeight } = useViewport()
  const { linear } = useSpring({
    linear: step,
    config: animationConfig,
  })

  if (step < 6) {
    return null
  }

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [`translate(0px, ${innerHeight - 200}px)`, 'translate(0px, 0px)'],
    },
    viewport7: {
      transform: [`translate(0px, ${innerHeight - 300}px)`, 'translate(0px, 0px)'],
    },
    viewport10: {
      transform: [`translate(0px, ${innerHeight - 400}px)`, 'translate(0px, 0px)'],
    },
    viewport12: {
      transform: ['translate(70vw, 0px)', 'translate(0vw, 0px)'],
    },
    viewport14: {
      transform: ['translate(70vw, 0px)', 'translate(0vw, 0px)'],
    },
    viewport16: {
      transform: ['translate(70vw, 0px)', 'translate(0vw, 0px)'],
    },
  }

  const CHAT_RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [`translate(0px, ${innerHeight - 200}px)`, 'translate(0px, 0px)'],
    },
    viewport7: {
      transform: [`translate(0px, 0px)`, 'translate(0px, -200px)'],
    },
    viewport10: {
      transform: [`translate(0px, 0px)`, 'translate(0px, -200px)'],
    },
    viewport12: {
      transform: [`translate(0px, 0px)`, 'translate(-100px, 0px)'],
    },
    viewport14: {
      transform: [`translate(0px, 0px)`, 'translate(-100px, 0px)'],
    },
    viewport16: {
      transform: [`translate(0px, 0px)`, 'translate(-100px, 0px)'],
    },
  }

  return (
    <Container
      style={{
        transform: linear.interpolate([6, 7], RESPONSIVE[viewport].transform),
      }}
    >
      <Hello step={step} />
      <ShallWeChat
        style={{
          opacity: linear.interpolate([6, 7], [1, 0]),
          transform: linear.interpolate([6, 7], CHAT_RESPONSIVE[viewport].transform),
        }}
      >
        <div>Shall we chat?</div>
        <div>Let&apos;s talk about your product</div>
      </ShallWeChat>
      <animated.div style={{ opacity: linear.interpolate([6, 7], [0, 1]) }}>
        <ContactForm />
      </animated.div>
    </Container>
  )
}
