import React from 'react'
import { animated, to, SpringValue, useSpring } from 'react-spring'
import { calculatePath } from './helpers'
import { animationConfig } from '../../utils/config'

export const OUTLINE_SHARP_POINTS = [
  {
    // Intro page
    x1y1: [0, 0],
    x2y2: [0, 0],
    x3y3: [0, 0],
    x4y4: [0, 0],
    x5y5: [0, 0],
    x6y6: [0, 0],
    x7y7: [0, 0],
    x8y8: [0, 0],
  },
  {
    // Corner page
    x1y1: [0, 0],
    x2y2: [0, 0],
    x3y3: [0, 0],
    x4y4: [0, 0],
    x5y5: [0, 0],
    x6y6: [0, 0],
    x7y7: [0, 0],
    x8y8: [0, 0],
  },
  {
    // Service page 1
    x1y1: [640, 410],
    x2y2: [770, 378],
    x3y3: [769, 518],
    x4y4: [640, 560],
    x5y5: [539, 523],
    x6y6: [663, 492],
    x7y7: [668, 348],
    x8y8: [540, 377],
  },
  {
    // Service page 2
    x1y1: [168, 555],
    x2y2: [290, 482],
    x3y3: [290, 685],
    x4y4: [168, 760],
    x5y5: [148, 750],
    x6y6: [270, 675],
    x7y7: [270, 472],
    x8y8: [148, 545],
  },
  {
    // Service page 3
    x1y1: [240, 310],
    x2y2: [540, 130],
    x3y3: [540, 350],
    x4y4: [240, 520],
    x5y5: [220, 510],
    x6y6: [520, 340],
    x7y7: [520, 120],
    x8y8: [220, 300],
  },
  {
    // Service page 4
    x1y1: [500, 380],
    x2y2: [640, 290],
    x3y3: [640, 380],
    x4y4: [500, 470],
    x5y5: [480, 460],
    x6y6: [620, 370],
    x7y7: [620, 280],
    x8y8: [480, 370],
  },
]

type Props = {
  x1y1: SpringValue<number[]>
  x2y2: SpringValue<number[]>
  x3y3: SpringValue<number[]>
  x4y4: SpringValue<number[]>
  x5y5: SpringValue<number[]>
  x6y6: SpringValue<number[]>
  x7y7: SpringValue<number[]>
  x8y8: SpringValue<number[]>
  opacity: SpringValue<number>
  transform: SpringValue<string>
  showBox2: boolean
}

export const OutlineSharp: React.FC<Props> = ({
  x1y1,
  x2y2,
  x3y3,
  x4y4,
  x5y5,
  x6y6,
  x7y7,
  x8y8,
  opacity,
  showBox2,
  transform,
}) => {
  const [box2, setBox2] = useSpring(() => ({
    from: {
      transform: 'translate(0px, 120px)',
      opacity: 0,
    },
    config: animationConfig,
  }))

  React.useEffect(() => {
    if (showBox2) {
      setBox2({ transform: 'translate(0px, 120px)', opacity: 1, delay: 300 })
      return
    }
    setBox2({ transform: 'translate(0px, 0px)', opacity: 0, delay: 0 })
  }, [setBox2, showBox2])

  return (
    <animated.g id="outline-sharp" opacity={opacity} style={{ transform }}>
      <animated.path
        fill="none"
        stroke="#696969"
        strokeWidth="1"
        className="face top"
        strokeLinecap="square"
        d={to([x1y1, x2y2, x7y7, x8y8], calculatePath)}
      />
      <animated.path
        fill="none"
        stroke="#696969"
        strokeWidth="1"
        className="face bottom"
        strokeLinecap="square"
        d={to([x4y4, x3y3, x6y6, x5y5], calculatePath)}
      />
      <animated.path
        fill="none"
        stroke="#696969"
        strokeWidth="1"
        className="face back"
        strokeLinecap="square"
        d={to([x5y5, x6y6, x7y7, x8y8], calculatePath)}
      />
      <animated.path
        fill="none"
        stroke="#fff"
        strokeWidth="1"
        className="face front"
        strokeLinecap="square"
        d={to([x4y4, x1y1, x2y2, x3y3, x4y4, x2y2], calculatePath)}
      />
      <animated.g style={box2}>
        <animated.path
          fill="none"
          stroke="#696969"
          strokeWidth="1"
          className="face top"
          strokeLinecap="square"
          d={to([x1y1, x2y2, x7y7, x8y8], calculatePath)}
        />
        <animated.path
          fill="none"
          stroke="#696969"
          strokeWidth="1"
          className="face bottom"
          strokeLinecap="square"
          d={to([x4y4, x3y3, x6y6, x5y5], calculatePath)}
        />
        <animated.path
          fill="none"
          stroke="#696969"
          strokeWidth="1"
          className="face back"
          strokeLinecap="square"
          d={to([x5y5, x6y6, x7y7, x8y8], calculatePath)}
        />
        <animated.path
          fill="none"
          stroke="#fff"
          strokeWidth="1"
          className="face front"
          strokeLinecap="square"
          d={to([x4y4, x1y1, x2y2, x3y3], calculatePath)}
        />
        <path d="M510, 390 580, 345 580, 355 510, 400Z" fill="#808080" />
        <path d="M510, 405 545, 382.5 545, 392.5 510, 415Z" fill="#808080" />
        <path d="M510, 420 580, 375 580, 385 510, 430Z" fill="#808080" />
      </animated.g>
    </animated.g>
  )
}
