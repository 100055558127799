import React from 'react'
import { useSpring, animated } from 'react-spring'
// @ts-ignore
import { interpolate } from 'flubber'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

type Props = { step: number }

export const OutlineObject: React.FC<Props> = ({ step }) => {
  const { linear } = useSpring({
    from: { linear: 4 },
    linear: step,
    config: animationConfig,
  })

  const { viewport } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [
        'scale(0.5) translate(160px, 100px)',
        'scale(0.5) translate(40px, 110px)',
        'scale(0.5) translate(40px, 80px)',
      ],
    },
    viewport7: {
      transform: ['translate(150px, 210px)', 'translate(70px, 165px)', 'translate(0px, 165px)'],
    },
    viewport10: {
      transform: ['translate(210px, 300px)', 'translate(95px, 190px)', 'translate(0px, 220px)'],
    },
    viewport12: {
      transform: ['translate(220px, 150px)', 'translate(90px, 140px)', 'translate(0px, 140px)'],
    },
    viewport14: {
      transform: ['translate(240px, 230px)', 'translate(110px, 140px)', 'translate(0px, 140px)'],
    },
    viewport16: {
      transform: ['translate(460px, 210px)', 'translate(110px, 150px)', 'translate(0px, 150px)'],
    },
  }

  if (step < 5) {
    return null
  }
  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents="none"
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      stroke="#282828"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: linear.interpolate([5, 6, 7], [1, 1, 0]),
        transform: linear.interpolate([5, 6, 7], RESPONSIVE[viewport].transform),
      }}
    >
      <animated.path
        d={linear.interpolate([5, 6], [1, 0]).interpolate(
          interpolate('M1,1 h42 v42 H1z', 'M1,61 a60,60 0 1,0 120,0 a60,60 0 1,0 -120,0', {
            maxSegmentLength: 1,
          }),
        )}
      />
    </animated.svg>
  )
}
