import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import { mediaQuery } from '../../utils/styled'

const Container = styled(animated.div)`
  font-weight: bold;
  line-height: 50px;
  color: #fff;
  opacity: 0.05;
  position: fixed;
  user-select: none;
  pointer-events: none;
  font-size: 369px;
  top: 50%;
  left: 200px;
  ${mediaQuery('viewport7')`
    font-size: 300px;
    top: 35%;
    left: 200px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 360px;
  `}
`
type Props = {
  step: number
}

export const ServiceBackground: React.FC<Props> = ({ step = 1 }) => {
  const { linear } = useSpring({ linear: step })
  return (
    <Container
      style={{
        transform: linear.interpolate([1, 2], ['translate(0px, -50%)', 'translate(-100px, -50%)']),
        zIndex: 1,
      }}
    >
      SERVICE
    </Container>
  )
}
