import React from 'react'
import styled from 'styled-components'
import { mediaQuery } from '../../utils/styled'
import { animated, useSpring } from 'react-spring'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

const SVG = styled(animated.svg)`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 100px);
  height: auto;
  z-index: 25;
  path {
    stroke-width: 3px;
  }
  ${mediaQuery('viewport7')`
    width: calc(100vw - 200px);
  `}
  ${mediaQuery('viewport10')`
    width: calc(100vw - 300px);
  `}
  ${mediaQuery('viewport12')`
    top: 50%;
    width: 760px;
    height: 249px;
  `}
`

type Props = {
  step: number
}

export const Hello: React.FC<Props> = ({ step = 6 }) => {
  const { viewport } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: ['translate(50px, -38px)', 'translate(50px, -38px)', 'translate(50px, -100px)'],
    },
    viewport7: {
      transform: [
        'translate(150px, 200px)',
        'translate(150px, -107px)',
        'translate(150px, -250px)',
      ],
    },
    viewport10: {
      transform: [
        'translate(200px, 200px)',
        'translate(200px, -135px)',
        'translate(200px, -300px)',
      ],
    },
    viewport12: {
      transform: ['translate(0px, -50%)', 'translate(-152px, -50%)', 'translate(-300px, -50%)'],
    },
    viewport14: {
      transform: ['translate(0px, -50%)', 'translate(-152px, -50%)', 'translate(-300px, -50%)'],
    },
    viewport16: {
      transform: ['translate(0px, -50%)', 'translate(-152px, -50%)', 'translate(-300px, -50%)'],
    },
  }

  const { linear } = useSpring({ linear: step, from: { linear: 5 }, config: animationConfig })

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 760 249"
      style={{
        opacity: linear.interpolate([5, 6, 7], [0, 1, 0]),
        transform: linear.interpolate([5, 6, 7], RESPONSIVE[viewport].transform),
      }}
    >
      <path
        d="M18.9 233.5h42.47v-89.694h91.773V233.5h42.471V19.66h-42.471v85.54H61.37V19.66H18.9zm293.435 3.267c19.3 0 35.937-5.049 49.6-14.85s22.275-22.869 25.542-38.907H345.6c-4.752 13.365-15.444 20.2-32.076 20.2-20.493 0-36.234-13.365-36.531-35.64h111.671V153.9c0-23.463-6.831-42.471-20.79-56.727-13.959-13.959-32.67-21.087-55.836-21.087-22.869 0-41.58 7.425-55.539 21.978-14.256 14.85-21.384 34.155-21.384 58.212s7.128 43.659 21.384 58.212c13.959 14.854 32.67 22.279 55.836 22.279zm-35.046-96.525c1.485-20.493 16.038-31.779 34.749-31.779 19.008 0 33.561 11.286 35.046 31.779zM425.492 233.5h41.58V10.453h-41.58zm83.457 0h41.58V10.453h-41.58zm159.192 2.97c23.76 0 43.065-7.425 58.212-22.275 15.147-14.553 22.572-33.858 22.572-57.915 0-23.76-7.425-43.065-22.572-58.212-15.147-14.85-34.452-22.275-58.212-22.275s-43.065 7.425-58.212 22.275c-15.147 15.147-22.572 34.452-22.572 58.212 0 24.057 7.425 43.362 22.572 57.915 15.147 14.85 34.452 22.275 58.212 22.275zm0-35.343c-11.583 0-21.087-4.158-27.918-12.474-6.831-8.019-10.4-19.008-10.4-32.67s3.564-24.651 10.4-32.67 16.335-12.177 27.918-12.177 21.087 4.158 27.918 12.177 10.4 19.008 10.4 32.67-3.564 24.651-10.4 32.67c-6.831 8.316-16.335 12.474-27.918 12.474z"
        fill="none"
        stroke="#999"
      />
    </SVG>
  )
}
