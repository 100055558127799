import React from 'react'
import { useSpring, animated } from 'react-spring'
import { BLACK_SHARP_POINTS, BlackSharp } from './BlackSharp'
import { OUTLINE_SHARP_POINTS, OutlineSharp } from './OutlineSharp'
import { Keyboard } from './Keyboard'
import { MiniBoxs } from './MiniBoxs'
import { Watch } from './Watch'
import { ROUTES } from '../../index.d'
import styled from 'styled-components'
import { mediaQuery } from '../../utils/styled'
import { useViewport, ViewportKeys } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

const SVG = styled(animated.svg)`
  position: absolute;
  top: 0;
  left: 0;
  ${mediaQuery('viewport12')`
    left: 50%;
  `}
`

type Props = {
  step?: number
  route?: ROUTES
}

export const AnimatedSVGs: React.FC<Props> = ({ step = 0, route = ROUTES.HomeIntro }) => {
  const previousStep = React.useRef(step)

  const { viewport, innerWidth } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [
        'scale(5) translate(-80px, -25px)',
        'scale(0.6) translate(-110px, -280px)',
        'scale(0.4) translate(-517px, -470px)',
        'scale(0.4) translate(-517px, -470px)',
      ],
    },
    viewport7: {
      transform: [
        'scale(6) translate(-25px, 0px)',
        'scale(0.6) translate(300px, 300px)',
        'scale(0.6) translate(-10px, -100px)',
        'scale(0.6) translate(-10px, -100px)',
      ],
    },
    viewport10: {
      transform: [
        'scale(8) translate(-10px, 15px)',
        'scale(1) translate(340px, 320px)',
        'scale(1) translate(100px, 30px)',
        'scale(1) translate(100px, 30px)',
      ],
    },
    viewport12: {
      transform: [
        'scale(6) translate(-70px, -30px)',
        'scale(1) translate(-90px, -20px)',
        'scale(0.8) translate(-140px, -30px)',
        `scale(0.8) translate(-${innerWidth / 2 + 250}px, -30px)`,
      ],
    },
    viewport14: {
      transform: [
        'scale(8) translate(-60px, -10px)',
        'scale(1) translate(0px, 0px)',
        'scale(0.9) translate(-90px, -10px)',
        `scale(0.8) translate(-${innerWidth / 2 + 250}px, -30px)`,
      ],
    },
    viewport16: {
      transform: [
        'scale(8) translate(-50px, -10px)',
        'scale(1.2) translate(10px, 110px)',
        'scale(1) translate(-50px, 50px)',
        `scale(1) translate(-${innerWidth / 2}px, 80px)`,
      ],
    },
  }

  const [{ transform }, setSpring] = useSpring(() => ({
    transform: RESPONSIVE[viewport].transform[0],
    config: animationConfig,
  }))
  const [blackSharpProps, setBlackSharpSpring] = useSpring(() => ({
    ...BLACK_SHARP_POINTS[0],
    opacity: 1,
  }))
  const [outlineSharpProps, setOutlineSharpSpring] = useSpring(() => ({
    ...OUTLINE_SHARP_POINTS[2],
    from: { transform: 'translate(200px, 0px)' },
    opacity: 0,
    delay: 500,
  }))

  React.useEffect(() => {
    if (route === ROUTES.HomeIntro) {
      if (step === 0) {
        setSpring({ transform: RESPONSIVE[viewport].transform[0] })
        setBlackSharpSpring({
          ...BLACK_SHARP_POINTS[0],
          opacity: 1,
        })
        setOutlineSharpSpring({
          transform: 'translate(200px, 0px)',
          opacity: 0,
        })
        return
      }
    }
    if (route === ROUTES.Corner) {
      if (step === 0) {
        setSpring({ transform: RESPONSIVE[viewport].transform[1] })
        setBlackSharpSpring({
          ...BLACK_SHARP_POINTS[1],
          opacity: 1,
        })
        setOutlineSharpSpring({
          transform: 'translate(200px, 0px)',
          opacity: 0,
        })
        return
      }
      if (step > 2) {
        setBlackSharpSpring({
          opacity: 0,
        })
        return
      }
    }
    if (route === ROUTES.Service) {
      if (step === 0) {
        setSpring({
          transform: RESPONSIVE[viewport].transform[2],
        })
        setBlackSharpSpring({
          ...BLACK_SHARP_POINTS[2],
          opacity: 1,
        })
        setOutlineSharpSpring({
          ...OUTLINE_SHARP_POINTS[2],
          opacity: 1,
          transform: 'translate(0px, 0px)',
          delay: 0,
        })
        return
      }
      if (step === 1) {
        setSpring({
          transform: RESPONSIVE[viewport].transform[3],
        })
        setBlackSharpSpring({
          ...BLACK_SHARP_POINTS[3],
          opacity: 1,
        })
        setOutlineSharpSpring({
          ...OUTLINE_SHARP_POINTS[3],
          opacity: 1,
          delay: 0,
        })
        return
      }
      if (step === 2) {
        setBlackSharpSpring({
          ...BLACK_SHARP_POINTS[4],
          opacity: 1,
        })
        setOutlineSharpSpring({
          ...OUTLINE_SHARP_POINTS[4],
          opacity: 1,
        })
        return
      }
      if (step === 3) {
        setBlackSharpSpring({
          ...BLACK_SHARP_POINTS[5],
          opacity: 1,
        })
        setOutlineSharpSpring({
          ...OUTLINE_SHARP_POINTS[5],
          opacity: 1,
        })
        return
      }
    }
  }, [route, setBlackSharpSpring, setOutlineSharpSpring, setSpring, step, viewport])

  React.useEffect(() => {
    previousStep.current = step
  })

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 800 800"
      style={{ transform }}
    >
      <defs>
        <linearGradient
          id="front"
          x1=".121"
          y1=".307"
          x2="1.089"
          y2=".661"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#171818" />
          <stop offset="1" stopColor="#323232" />
        </linearGradient>
        <linearGradient
          id="left"
          x1=".183"
          y1=".658"
          x2="1.191"
          y2=".308"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#1e1e1f" />
          <stop offset="1" stopColor="#444544" />
        </linearGradient>
        <linearGradient
          id="top"
          x1="-.105"
          y1=".274"
          x2=".439"
          y2=".671"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#313131" />
          <stop offset="1" stopColor="#171717" />
        </linearGradient>
        <linearGradient
          id="watch-a"
          x1=".162"
          y1="1.259"
          x2=".704"
          y2="-.083"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#313131" />
          <stop offset="1" stopColor="#171717" />
        </linearGradient>
        <linearGradient id="watch-c" x1=".154" y1=".761" x2=".616" y2="0" xlinkHref="#watch-a" />
        <linearGradient id="watch-d" x1=".637" y1="1.246" x2=".616" y2="0" xlinkHref="#watch-a" />
        <linearGradient id="watch-e" x1="1" y1=".331" x2=".248" y2=".798" xlinkHref="#watch-a" />
        <linearGradient
          id="watch-f"
          x1="-.105"
          y1=".274"
          x2=".439"
          y2=".671"
          xlinkHref="#watch-a"
        />
        <linearGradient
          id="watch-g"
          x1="-2.479"
          y1="1.687"
          x2="1.191"
          y2=".308"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#1e1e1f" />
          <stop offset="1" stopColor="#444544" />
        </linearGradient>
        <linearGradient
          id="watch-h"
          x1=".715"
          y1=".397"
          x2="-.039"
          y2="1.098"
          xlinkHref="#watch-a"
        />
        <linearGradient
          id="keyboard-b"
          x1=".121"
          y1=".307"
          x2="1.089"
          y2=".661"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#171818" />
          <stop offset="1" stopColor="#323232" />
        </linearGradient>
        <linearGradient
          id="keyboard-c"
          x1=".183"
          y1=".658"
          x2="1.191"
          y2=".308"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#1e1e1f" />
          <stop offset="1" stopColor="#444544" />
        </linearGradient>
        <linearGradient
          id="keyboard-d"
          x1="-.105"
          y1=".274"
          x2=".439"
          y2=".671"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#313131" />
          <stop offset="1" stopColor="#171717" />
        </linearGradient>
      </defs>
      <Keyboard show={step === 2 && route === ROUTES.Service} step={step + 1} />
      <BlackSharp {...blackSharpProps} />
      <OutlineSharp {...outlineSharpProps} showBox2={step === 3} />
      <MiniBoxs show={step === 0 && route === ROUTES.Service} />
      <Watch show={step === 1 && route === ROUTES.Service} step={step + 1} />
    </SVG>
  )
}
