import React from 'react'
import { useSpring, animated } from 'react-spring'
// @ts-ignore
import { interpolate } from 'flubber'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'
import { animationConfig, TRIANGLE_QUOTE } from '../../utils/config'

type Props = {
  step: number
}

export const BlackObject: React.FC<Props> = ({ step }) => {
  const { viewport, innerWidth, innerHeight } = useViewport()

  const { linear } = useSpring({
    from: {
      linear: 0,
      xy: [innerWidth, innerHeight],
    },
    linear: step,
    xy: [0, 0],
    config: animationConfig,
  })

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [
        `scale(1) translate(${innerWidth}px, ${innerHeight}px) rotate(0deg)`,
        `scale(1) translate(${innerWidth / 2 - 50}px, ${innerHeight - 30}px) rotate(0deg)`,
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(0.5) translate(0px, 60px) rotate(180deg)',
        'scale(0.5) translate(-40px, 70px) rotate(0deg)',
        'scale(0.5) translate(-40px, 40px) rotate(0deg)',
      ],
    },
    viewport7: {
      transform: [
        `scale(1) translate(${innerWidth}px, ${innerHeight}px) rotate(0deg)`,
        `scale(1) translate(${innerWidth / 2 + 20}px, ${innerHeight - 100}px) rotate(0deg)`,
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(50px, 200px) rotate(180deg)',
        'scale(1) translate(30px, 150px) rotate(0deg)',
        'scale(1) translate(-50px, 130px) rotate(0deg)',
      ],
    },
    viewport10: {
      transform: [
        `scale(1) translate(${innerWidth}px, ${innerHeight}px) rotate(0deg)`,
        `scale(1) translate(${innerWidth / 2 + 50}px, ${innerHeight - 100}px) rotate(0deg)`,
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(100px, 300px) rotate(180deg)',
        'scale(1) translate(50px, 170px) rotate(0deg)',
        'scale(1) translate(-50px, 250px) rotate(0deg)',
      ],
    },
    viewport12: {
      transform: [
        `scale(1) translate(${innerWidth}px, ${innerHeight}px) rotate(0deg)`,
        `scale(1) translate(${innerWidth / 2 - 100}px, ${innerHeight - 100}px) rotate(0deg)`,
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(120px, 120px) rotate(180deg)',
        'scale(1) translate(50px, 130px) rotate(0deg)',
        'scale(1) translate(-50px, 100px) rotate(0deg)',
      ],
    },
    viewport14: {
      transform: [
        `scale(1) translate(${innerWidth}px, ${innerHeight}px) rotate(0deg)`,
        `scale(1) translate(${innerWidth / 2 + 50}px, ${innerHeight - 100}px) rotate(0deg)`,
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(130px, 200px) rotate(180deg)',
        'scale(1) translate(70px, 130px) rotate(0deg)',
        'scale(1) translate(-70px, 100px) rotate(0deg)',
      ],
    },
    viewport16: {
      transform: [
        `scale(1) translate(${innerWidth}px, ${innerHeight}px) rotate(0deg)`,
        `scale(1) translate(${innerWidth / 2 + 50}px, ${innerHeight - 100}px) rotate(0deg)`,
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(0px, 0px) rotate(0deg)',
        'scale(1) translate(340px, 200px) rotate(180deg)',
        'scale(1) translate(70px, 140px) rotate(0deg)',
        'scale(1) translate(-70px, 110px) rotate(0deg)',
      ],
    },
  }

  const [{ width, height, opacity, transform }, setSpring] = useSpring(() => ({
    width: innerWidth,
    height: innerHeight,
    opacity: 0,
    transform: RESPONSIVE[viewport].transform[0],
    config: { mass: 1, tension: 120, friction: 30 },
  }))

  React.useEffect(() => {
    switch (step) {
      case 0:
        setSpring({
          width: innerWidth,
          height: innerHeight,
          opacity: 1,
          transform: RESPONSIVE[viewport].transform[0],
        })
        return
      case 1:
        setSpring({
          width: innerWidth,
          height: innerHeight,
          opacity: 1,
          transform: RESPONSIVE[viewport].transform[1],
        })
        return
      case 2:
        setSpring({
          width: innerWidth,
          height: innerHeight,
          transform: RESPONSIVE[viewport].transform[2],
        })
        return
      case 3:
        setSpring({
          width: innerWidth,
          height: innerHeight,
          transform: RESPONSIVE[viewport].transform[3],
        })
        return
      case 4:
        setSpring({
          width: innerWidth,
          height: innerHeight,
          transform: RESPONSIVE[viewport].transform[4],
        })
        return
      case 5:
        setSpring({
          width: 207,
          height: 182,
          transform: RESPONSIVE[viewport].transform[5],
        })
        return
      case 6:
        setSpring({
          transform: RESPONSIVE[viewport].transform[6],
          opacity: 1,
        })
        return
      case 7:
        setSpring({
          transform: RESPONSIVE[viewport].transform[7],
          opacity: 0,
        })
        return
      default:
        return
    }
  }, [setSpring, step, viewport, innerHeight, innerWidth, RESPONSIVE])

  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents="none"
      width={width}
      height={height}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        opacity,
        transform,
        zIndex: 10,
      }}
      viewBox={`0 0 ${width} ${height}`}
    >
      {step <= 5 && (
        <animated.path
          d={linear
            .interpolate([1, 2, 3, 4, 5], [0, 0, 0, 0, 1])
            .interpolate(
              interpolate(
                `M0,0 ${innerWidth},0 ${innerWidth},${innerHeight}L0,${innerHeight}Z`,
                'M0 0h207l-98.438 182L0 0',
              ),
            )}
          fill="black"
        />
      )}
      {step > 5 && (
        <animated.path
          d={linear.interpolate([5, 6], [0, 1]).interpolate(TRIANGLE_QUOTE)}
          fill="black"
        />
      )}
    </animated.svg>
  )
}
