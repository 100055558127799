import React from 'react'
import 'styled-components/macro'
import { ContactForm } from './ContactForm'
import { useTransition, animated } from 'react-spring'

type Props = {}

export const Contact: React.FC<Props> = () => {
  const [showLeft, setShowLeft] = React.useState(true)
  const transition = useTransition(showLeft, {
    from: {
      linear: 0,
    },
    enter: {
      linear: 1,
    },
    leave: {
      linear: 0,
    },
  })

  React.useEffect(() => {
    setShowLeft(true)
  }, [])
  return (
    <React.Fragment>
      {transition(
        (style, item) =>
          item && (
            <animated.div
              css={`
                position: absolute;
                top: 0;
                left: 0;
                width: 50vw;
                height: 100vh;
                background: black;
                z-index: 19;
              `}
              style={{
                transform: style.linear.interpolate([0, 1], ['translateY(100%)', 'translateY(0%)']),
                opacity: style.linear,
              }}
            />
          ),
      )}
      {transition(
        (style, item) =>
          item && (
            <animated.div
              css={`
                position: absolute;
                top: 0;
                right: 0;
                width: 50vw;
                height: 100vh;
                background: black;
                z-index: 19;
              `}
              style={{
                transform: style.linear.interpolate(
                  [0, 1],
                  ['translateY(-100%)', 'translateY(0%)'],
                ),
                opacity: style.linear,
              }}
            />
          ),
      )}
      <div
        css={`
          position: relative;
          z-index: 20;
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <ContactForm />
      </div>
    </React.Fragment>
  )
}
