import React from 'react'
import styled from 'styled-components'
import { mediaQuery } from '../utils/styled'

const Container = styled('div')`
  position: fixed;
  left: 25px;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 100;
  & > div {
    font-weight: 300;
    margin-bottom: 8px;
    &:nth-child(3n + 1) {
      font-weight: 700;
    }
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 5;
    }
    &:nth-child(4) {
      order: 2;
    }
    &:nth-child(5) {
      order: 4;
    }
    &:nth-child(6) {
      order: 6;
    }
  }
  ${mediaQuery('viewport7')`
    left: 48px;
    bottom: 48px;
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(3, 1fr);
    grid-gap: 8px 32px;
    & > div {
      order: initial !important;
      margin-bottom: 0;
    }
  `}
`

type Props = {}

export const Footer: React.FC<Props> = () => {
  return (
    <Container>
      <div>Missing Corner, Inc.</div>
      <div>hello@missingcorner.com</div>
      <div>+1 (669) 292-4613</div>
    </Container>
  )
}
