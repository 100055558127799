import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { mediaQuery } from '../../utils/styled'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

const Container = styled(animated.div)`
  position: absolute;
  z-index: 20;
  background: white;
  left: 25px;
  bottom: 75px;
  padding: 25px;
  width: calc(100vw - 50px);
  ${mediaQuery('viewport7')`
    left: 50px;
    bottom: 100px;
    width: calc(100vw - 100px);
    height: 50vh;
    padding: 50px;
  `}
  ${mediaQuery('viewport10')`
    left: 100px;
    bottom: 100px;
    width: calc(100vw - 200px);
    height: 50vh;
    padding: 100px;
  `}
  ${mediaQuery('viewport12')`
    left: 50%;
    top: 120px;
    bottom: unset;
    width: calc(50vw - 100px);
    height: calc(100vh - 220px);
    padding: 50px;
  `}
  ${mediaQuery('viewport16')`
    height: calc(100vh - 300px);
    padding: 80px;
  `}
`

const Title = styled('div')`
  font-weight: 500;
  letter-spacing: 0.01em;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  color: #000;
  font-size: 24px;
  line-height: 32px;
  ${mediaQuery('viewport7')`
    font-size: 32px;
    line-height: 40px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 48px;
    line-height: 50px;
  `}
  ${mediaQuery('viewport12')`
    font-size: 32px;
    line-height: 40px;
  `}
  ${mediaQuery('viewport16')`
    font-size: 48px;
    line-height: 50px;
  `}
`

const Description = styled('div')`
  font-weight: 300;
  font-size: 24px;
  line-height: 35px;
  text-align: left;
  color: #000;
  opacity: 0.5;
  margin: 16px 0 16px;
  font-size: 16px;
  line-height: 24px;
  ${mediaQuery('viewport12')`
    font-size: 18px;
    margin-bottom: 0;
  `}
  ${mediaQuery('viewport14')`
    margin: 30px 0 50px;
  `}
  ${mediaQuery('viewport16')`
    font-size: 24px;
  `}
`

const GroupContainer = styled('div')`
  display: flex;
  flex-direction: column;
  .group {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 31px;
    text-align: left;
    color: #000;
    opacity: 0.5;
    img {
      width: 48px;
      height: 48px;
    }
    .title {
      margin-top: 16px;
      margin-left: 16px;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      opacity: inherit;
    }
  }
  ${mediaQuery('viewport7')`
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 32px;
    .group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-basis: 50%;
      img {
        width: auto;
        height: auto;
      }
      .title {
        margin-left: 0;
      }
    }
  `}
  ${mediaQuery('viewport10')`
    margin-top: 48px;
  `}
  ${mediaQuery('viewport12')`
    margin-top: 32px;
  `}
  ${mediaQuery('viewport16')`
    margin-top: 48px;
    .group {
      font-size: 18px;
    }
  `}
`

type Props = {
  step?: number
}

const Icons = [
  require('../../assets/icons/chess-icon.svg'),
  require('../../assets/icons/goal-icon.svg'),
  require('../../assets/icons/ux-icon.svg'),
  require('../../assets/icons/front-end-icon.svg'),
]

export const BelieveIn: React.FC<Props> = ({ step = 3 }) => {
  const { linear } = useSpring({
    linear: step,
    config: animationConfig,
  })
  const { viewport, innerWidth } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [`translate(${innerWidth}px, 0px)`, 'translate(0px, 0px)', 'translate(-300px, 0)'],
    },
    viewport7: {
      transform: [`translate(${innerWidth}px, 0px)`, 'translate(0px, 0px)', 'translate(-300px, 0)'],
    },
    viewport10: {
      transform: [`translate(${innerWidth}px, 0px)`, 'translate(0px, 0px)', 'translate(-300px, 0)'],
    },
    viewport12: {
      transform: [`translate(${innerWidth}px, 0px)`, 'translate(0px, 0px)', 'translate(-300px, 0)'],
    },
    viewport14: {
      transform: [`translate(${innerWidth}px, 0px)`, 'translate(0px, 0px)', 'translate(-600px, 0)'],
    },
    viewport16: {
      transform: [`translate(${innerWidth}px, 0px)`, 'translate(0px, 0px)', 'translate(-300px, 0)'],
    },
  }

  if (step < 3) {
    return null
  }

  return (
    <Container
      style={{
        opacity: linear.interpolate([3, 4, 5], [0, 1, 0]),
        transform: linear
          .interpolate([3, 4, 5], [0, 1, 2])
          .interpolate([0, 1, 2], RESPONSIVE[viewport].transform),
      }}
    >
      <Title>We believe in invention beat convetion</Title>
      <Description>Human insight and fresh ideas drives us through every stage.</Description>
      <GroupContainer>
        <div className="group">
          <img src={Icons[0]} className="icon" />
          <h2 className="title">Research & Strategy</h2>
        </div>
        <div className="group">
          <img src={Icons[1]} className="icon" />
          <h2 className="title">Experience Definition</h2>
        </div>
        <div className="group">
          <img src={Icons[2]} className="icon" />
          <h2 className="title">Experience Design</h2>
        </div>
        <div className="group">
          <img src={Icons[3]} className="icon" />
          <h2 className="title">Development</h2>
        </div>
      </GroupContainer>
    </Container>
  )
}
