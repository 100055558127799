import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import 'styled-components/macro'
import { useViewport, ViewportKeys } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'
import { mediaQuery } from '../../utils/styled'
import { DesignerStats } from './DesignerStats'

const Content = styled(animated.div)`
  padding: 20px 0px;
  position: relative;
`

const Title = styled(animated.div)`
  letter-spacing: 0.02em;
  color: inherit;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 16px;
  ${mediaQuery('viewport7')`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 34px;
  `}
`

const Description = styled(animated.div)`
  font-weight: 300;
  opacity: 0.5;
  color: inherit;
  font-size: 14px;
  line-height: 20px;
  p {
    padding-bottom: 20px;
  }
  ${mediaQuery('viewport7')`
    font-size: 18px;
    line-height: 26px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 24px;
    line-height: 35px;
  `}
`

const DeveloperContainer = styled(animated.div)`
  position: absolute;
  top: 74px;
  left: 0;
  color: white;
  z-index: 20;
  width: 100vw;
  img {
    width: 200px;
    height: 200px;
    transform: translateX(-100px);
  }
  ${Content} {
    position: absolute;
    bottom: -40px;
    right: 25px;
    left: 75px;
  }
  ${mediaQuery('viewport7')`
    top: 120px;
    left: 0;
    img {
      width: 300px;
      height: 300px;
      transform: translateX(-50px);
    }
    ${Content} {
      position: absolute;
      bottom: 0;
      right: 100px;
      left: 200px;
    }
  `}
  ${mediaQuery('viewport10')`
    img {
      width: 350px;
      height: 350px;
    }
    ${Content} {
      left: 260px;
      right: 150px;
    }
  `}
  ${mediaQuery('viewport12')`
    width: 50vw;
    height: calc(100vh - 220px);
    img {
      width: auto;
      height: 50%;
      transform: translateX(190px);
    }
    ${Content} {
      position: relative;
      padding: 20px 50px;
      left: unset;
      right: unset;
    }
  `}
  ${mediaQuery('viewport14')`
    img {
      margin-bottom: 32px;
    }
  `}
  ${mediaQuery('viewport16')`
    padding: 50px 80px 0;
  `}
`

const DesignerContainer = styled(animated.div)`
  position: absolute;
  background: white;
  color: black;
  z-index: 20;
  top: 0;
  right: 0;
  width: calc(100vw - 50px);
  height: calc(50vh - 75px);
  padding: 25px;
  svg {
    width: 120px;
    height: 100px;
  }
  ${mediaQuery('viewport7')`
    padding: 20px 50px 50px;
    top: 0;
    right: 0;
    width: calc(100vw - 120px);
    height: calc(50vh - 100px);
    svg {
      width: 252px;
      height: 210px;
    }
  `}
  ${mediaQuery('viewport10')`
    svg {
      width: 372px;
      height: 310px;
    }
  `}
  ${mediaQuery('viewport12')`
    top: 120px;
    width: 50vw;
    height: calc(100vh - 120px);
    svg {
      width: 276px;
      height: 230px;
    }
  `}
  ${mediaQuery('viewport14')`
    svg {
      width: 336px;
      height: 280px;
      margin-bottom: 40px;
    }
  `}
  ${mediaQuery('viewport16')`
    padding: 0px 80px 0;
    svg {
      margin: 60px 0;
    }
  `}
`

type Props = {
  active?: boolean
  step?: number
}

export const DevAndDesign: React.FC<Props> = ({ step = 0 }) => {
  const { linear } = useSpring({ linear: step, config: animationConfig })

  const { viewport, innerWidth, innerHeight } = useViewport()

  const DESIGNER_CONTAINER_RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [
        `translate(${innerWidth - 120}px, ${innerHeight / 2}px)`,
        `translate(0px, ${innerHeight / 2}px)`,
        'translate(0px, 74px)',
        'translate(-250px, 74px)',
      ],
    },
    viewport7: {
      transform: [
        `translate(${innerWidth - 120}px, ${innerHeight / 2}px)`,
        `translate(0px, ${innerHeight / 2}px)`,
        'translate(-120px, 120px)',
        'translate(-400px, 120px)',
      ],
    },
    viewport10: {
      transform: [
        `translate(${innerWidth - 120}px, ${innerHeight / 2}px)`,
        `translate(0px, ${innerHeight / 2}px)`,
        'translate(-120px, 120px)',
        'translate(-400px, 120px)',
      ],
    },
    viewport12: {
      transform: [
        `translate(${innerWidth - 120}px, ${innerHeight / 2}px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth / 2}px, -120px)`,
        `translate(-${innerWidth / 2 + 300}px, -120px)`,
      ],
    },
    viewport14: {
      transform: [
        `translate(${innerWidth - 120}px, 120px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth / 2}px, -120px)`,
        `translate(-${innerWidth / 2 + 300}px, -120px)`,
      ],
    },
    viewport16: {
      transform: [
        `translate(${innerWidth - 120}px, 120px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth / 2}px, -120px)`,
        `translate(-${innerWidth / 2 + 300}px, -120px)`,
      ],
    },
  }

  return (
    <React.Fragment>
      <DeveloperContainer
        style={{
          opacity: linear.interpolate([0, 1, 2], [0, 1, 0]),
        }}
      >
        <img src={require('../../assets/outline-box.svg')} />
        <Content>
          <Title>Build</Title>
          <Description>
            <p>
              At Missing Corner, we have some of the best development talent in the business
              building apps & platform that get brands noticed. With experience delivering
              everything from iOS and Android apps to emerging technologies such as mixed reality
              and artificial intelligence (AI), our team is guaranteed to have the solution you
              need.
            </p>
            <p>
              By partnering with us you can integrate our development expertise into the core of
              your business and position yourself as a leader in emerging technologies.
            </p>
          </Description>
        </Content>
      </DeveloperContainer>
      <DesignerContainer
        style={{
          opacity: linear.interpolate([0, 1, 2, 3], [0, 1, 1, 0]),
          transform: linear.interpolate(
            [0, 1, 2, 3],
            DESIGNER_CONTAINER_RESPONSIVE[viewport].transform,
          ),
        }}
      >
        <DesignerStats step={step} />
        <Content
          style={{
            opacity: linear.interpolate([0, 1, 2], [0, 1, 0]),
            height: 0,
          }}
        >
          <Title>Design</Title>
          <Description>
            <p>
              The work of our world-class design team is at the core of all our projects and runs
              throughout all of our services. Remarkable design in digital creativity is crucial,
              and will ultimately help you to exceed the expectations of your customers.
            </p>

            <p>
              Our designers will precisely comprehend your brand to delight your target audiences.
              Through extensive competitor analysis, market and user research, our deliverables can
              include elevator pitches, prototypes, digital roadmaps and more.
            </p>
          </Description>
        </Content>
        <Content
          style={{
            position: 'absolute',
            opacity: linear.interpolate([1, 2, 3], [0, 1, 0]),
          }}
        >
          <Title>Values</Title>
          <Description>
            <p>
              With a presence spreading across multiple continents, we are an ever-growing team who
              strive to be every company&apos;s #missing-corner. Passionate about technology, we
              work with partners to deliver successful, innovative solutions.
            </p>
          </Description>
        </Content>
      </DesignerContainer>
    </React.Fragment>
  )
}
