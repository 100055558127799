import React from 'react'
import styled from 'styled-components'
import { useTransition, animated } from 'react-spring'
import { mediaQuery } from '../../utils/styled'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'

const Container = styled(animated.div)`
  position: absolute;
  top: calc(50% + 100px);
  left: 25px;
  width: calc(100vw - 100px);
  ${mediaQuery('viewport7')`
    top: 50%;
    left: 50px;
    width: calc(50vw - 50px);
  `}
  ${mediaQuery('viewport10')`
    width: calc(50vw - 50px);
  `}
  ${mediaQuery('viewport12')`
    left: 100px;
    width: calc(50vw - 50px);
  `}
  ${mediaQuery('viewport14')``}
  ${mediaQuery('viewport16')``}
`

const SmallText = styled('div')`
  font-weight: 300;
  color: #282828;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
  ${mediaQuery('viewport7')`
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 28px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 32px;
    line-height: 40px;
  `}
`

const MediumText = styled('div')`
  font-weight: 300;
  color: #282828;
  opacity: 0.5;
  letter-spacing: 0.3px;
  margin-top: 16px;
  ${mediaQuery('viewport7')`
    margin-top: 16px;
    font-size: 24px;
    line-height: 35px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 32px;
    line-0height: 40px;
  `}
`

const LargeText = styled('div')`
  font-weight: bold;
  font-size: 42px;
  line-height: 42px;
  color: #282828;
  letter-spacing: 0.3px;
  ${mediaQuery('viewport7')`
    font-size: 54px;
    line-height: 59px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 88px;
    line-height: 88px;
  `}
`

type Props = {
  active?: boolean
  step: number
}

export const ThinkOutside: React.FC<Props> = ({ active = false }) => {
  const { viewport } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: ['translate(-250px, -50%)', 'translate(0px, -50%)'],
    },
    viewport7: {
      transform: ['translate(-250px, -50%)', 'translate(0px, -50%)'],
    },
    viewport10: {
      transform: ['translate(-250px, -50%)', 'translate(0px, -50%)'],
    },
    viewport12: {
      transform: ['translate(-250px, -50%)', 'translate(0px, -50%)'],
    },
    viewport14: {
      transform: ['translate(-250px, -50%)', 'translate(0px, -50%)'],
    },
    viewport16: {
      transform: ['translate(-250px, -50%)', 'translate(0px, -50%)'],
    },
  }

  const transition = useTransition(active, {
    from: {
      opacity: 0,
      transform: RESPONSIVE[viewport].transform[0],
    },
    enter: {
      opacity: 1,
      transform: RESPONSIVE[viewport].transform[1],
    },
    leave: {
      opacity: 0,
    },
  })

  return (
    <React.Fragment>
      {transition(
        (style, item) =>
          item && (
            <Container style={style}>
              <SmallText>Think</SmallText>
              <LargeText>Out side</LargeText>
              <LargeText>the Box</LargeText>
              <MediumText>For the world&apos;s most abitious companies</MediumText>
            </Container>
          ),
      )}
    </React.Fragment>
  )
}
