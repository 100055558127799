import React from 'react'
import { useSpring } from 'react-spring'
import { useViewport, ViewportKeys } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'
import { Container } from './AppDev'
import { Description, Title } from './Enterprise'

type Props = {
  step: number
}

export const WebDev: React.FC<Props> = ({ step = 2 }) => {
  const { viewport, innerWidth } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: { transform: ['translate(0px, 0px)'] },
    viewport7: {
      transform: [
        `translate(${innerWidth}px, 0px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth}px, 0px)`,
      ],
    },
    viewport10: {
      transform: [
        `translate(${innerWidth}px, 0px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth}px, 0px)`,
      ],
    },
    viewport12: {
      transform: [
        `translate(${innerWidth}px, -175px)`,
        `translate(0px, -175px)`,
        `translate(0px, -475px)`,
      ],
    },
    viewport14: {
      transform: [
        `translate(${innerWidth}px, -210px)`,
        `translate(0px, -210px)`,
        `translate(0px, -510px)`,
      ],
    },
    viewport16: {
      transform: [
        `translate(${innerWidth}px, -210px)`,
        `translate(0px, -210px)`,
        `translate(0px, -510px)`,
      ],
    },
  }

  const [style, setSpring] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: RESPONSIVE[viewport].transform[0],
    },
    config: animationConfig,
  }))

  React.useEffect(() => {
    switch (step) {
      case 2:
        setSpring({
          opacity: 0,
          transform: RESPONSIVE[viewport].transform[0],
        })
        return
      case 3:
        setSpring({
          opacity: 1,
          transform: RESPONSIVE[viewport].transform[1],
        })
        return
      case 4:
        setSpring({
          opacity: 0,
          transform: RESPONSIVE[viewport].transform[2],
        })
        return
      default:
        step > 4 &&
          setSpring({
            opacity: 0,
            transform: RESPONSIVE[viewport].transform[2],
          })
        step < 2 &&
          setSpring({
            opacity: 0,
            transform: RESPONSIVE[viewport].transform[0],
          })
        return
    }
  }, [RESPONSIVE, setSpring, step, viewport])

  return (
    <Container style={style}>
      <Title>Web Development</Title>
      <Description>
        Our experience with small screens comes in handy in a world where the majority of web
        traffic is on mobile. Our web team builds responsive sites and web applications that
        elegantly work across desktop and mobile, leveraging the same robust backend services we use
        for our apps.
        <br />
        <ul>
          <li>- React, Vue.js</li>
          <li>- Node</li>
          <li>- Serverless, Lamda</li>
          <li>- Interactive Visualization</li>
        </ul>
      </Description>
    </Container>
  )
}
