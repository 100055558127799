import React from 'react'
import { animated, useSpring } from 'react-spring'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

type Props = {
  step: number
}

export const DesignerStats: React.FC<Props> = ({ step = 0 }) => {
  const { linear } = useSpring({ linear: step, config: animationConfig })

  const { viewport, innerWidth } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [
        `translateX(${innerWidth / 4}px)`,
        `translateX(${innerWidth / 4}px)`,
        `translateX(0px)`,
        `translateX(0px)`,
      ],
    },
    viewport7: {
      transform: [
        'translate(150px, 0px)',
        'translate(150px, 0px)',
        'translate(0px, 0px)',
        'translate(0px, 0px)',
      ],
    },
    viewport10: {
      transform: [
        'translate(242px, 0px)',
        'translate(242px, 0px)',
        'translate(0px, 0px)',
        'translate(0px, 0px)',
      ],
    },
    viewport12: {
      transform: [
        'translate(142px, 0px)',
        'translate(142px, 0px)',
        'translate(0px, 60px)',
        'translate(0px, 60px)',
      ],
    },
    viewport14: {
      transform: [
        'translate(172px, 0px)',
        'translate(172px, 0px)',
        'translate(0px, 60px)',
        'translate(0px, 60px)',
      ],
    },
    viewport16: {
      transform: [
        `translate(${innerWidth / 4 - 228}px, 0px)`,
        `translate(${innerWidth / 4 - 228}px, 0px)`,
        'translate(0px, 60px)',
        'translate(0px, 60px)',
      ],
    },
  }

  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents="none"
      viewBox="0 0 363 302"
      style={{
        transform: linear.interpolate([0, 1, 2, 3], RESPONSIVE[viewport].transform),
      }}
    >
      <g fontSize="236" fontFamily="DINPro-Light, DINPro" fontWeight="300">
        <animated.text
          x={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [120, 0])}
          y={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [246, 244])}
          fontSize={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [236, 114])}
        >
          30
        </animated.text>
        <animated.text
          x={125}
          y={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [264, 244])}
          opacity={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [0, 1])}
          fontSize={41}
          fontFamily="NeurialGrotesk-Light, Neurial Grotesk"
        >
          + clients
        </animated.text>
        <animated.text
          fontSize={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [236, 114])}
          x={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [0, 6])}
          y={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [246, 119])}
          fill={linear
            .interpolate([0, 1, 2, 3], [0, 0, 1, 1])
            .interpolate([0, 1], ['rgba(255, 255, 255, 0)', 'rgba(0, 0, 0, 0.5)'])}
          stroke={linear
            .interpolate([0, 1, 2, 3], [0, 0, 1, 1])
            .interpolate([0, 1], ['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 0)'])}
        >
          4
        </animated.text>
        <animated.text
          x={73}
          y={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [130, 110])}
          opacity={linear.interpolate([0, 1, 2, 3], [0, 0, 1, 1]).interpolate([0, 1], [0, 0.5])}
          fontSize={41}
          fontFamily="NeurialGrotesk-Light, Neurial Grotesk"
        >
          continents
        </animated.text>
      </g>
    </animated.svg>
  )
}
