import React from 'react'
import { animated, to, SpringValue } from 'react-spring'
import { calculatePath } from './helpers'

export const BLACK_SHARP_POINTS = [
  {
    // Intro page
    x1y1: [470, 390],
    x2y2: [600, 260],
    x3y3: [600, 260],
    x4y4: [590, 470],
    x5y5: [590, 470],
    x6y6: [470, 590],
    x7y7: [470, 590],
    x8y8: [470, 390],
    x9y9: [200, 510],
    x10y10: [200, 310],
    x11y11: [200, 310],
    x12y12: [370, 210],
    x13y13: [600, 260],
  },
  {
    // Corner page
    x1y1: [350, 270],
    x2y2: [600, 230],
    x3y3: [600, 230],
    x4y4: [600, 520],
    x5y5: [600, 520],
    x6y6: [350, 580],
    x7y7: [350, 580],
    x8y8: [350, 270],
    x9y9: [210, 480],
    x10y10: [200, 210],
    x11y11: [200, 210],
    x12y12: [430, 180],
    x13y13: [600, 230],
  },
  {
    // Service page 1
    x1y1: [360, 330],
    x2y2: [610, 290],
    x3y3: [610, 290],
    x4y4: [610, 590],
    x5y5: [610, 590],
    x6y6: [360, 640],
    x7y7: [360, 640],
    x8y8: [360, 330],
    x9y9: [220, 550],
    x10y10: [210, 280],
    x11y11: [210, 280],
    x12y12: [440, 250],
    x13y13: [610, 290],
  },
  {
    // Service page 2
    x1y1: [220, 165],
    x2y2: [390, 60],
    x3y3: [410, 80],
    x4y4: [410, 460],
    x5y5: [390, 500],
    x6y6: [235, 590],
    x7y7: [200, 578],
    x8y8: [200, 195],
    x9y9: [160, 555],
    x10y10: [158, 170],
    x11y11: [180, 140],
    x12y12: [350, 40],
    x13y13: [390, 60],
  },
  {
    // Service page 3
    x1y1: [162, 287],
    x2y2: [515, 82],
    x3y3: [523, 90],
    x4y4: [520, 380],
    x5y5: [520, 380],
    x6y6: [150, 583],
    x7y7: [150, 583],
    x8y8: [150, 305],
    x9y9: [133, 575],
    x10y10: [133, 295],
    x11y11: [145, 275],
    x12y12: [496, 75],
    x13y13: [515, 82],
  },
  {
    // Service page 4
    x1y1: [442, 215],
    x2y2: [508, 250],
    x3y3: [508, 250],
    x4y4: [506, 540],
    x5y5: [506, 540],
    x6y6: [348, 650],
    x7y7: [348, 650],
    x8y8: [348, 350],
    x9y9: [210, 580],
    x10y10: [210, 270],
    x11y11: [318, 140],
    x12y12: [318, 110],
    x13y13: [442, 180],
  },
]

type Props = {
  x1y1: SpringValue<number[]>
  x2y2: SpringValue<number[]>
  x3y3: SpringValue<number[]>
  x4y4: SpringValue<number[]>
  x5y5: SpringValue<number[]>
  x6y6: SpringValue<number[]>
  x7y7: SpringValue<number[]>
  x8y8: SpringValue<number[]>
  x9y9: SpringValue<number[]>
  x10y10: SpringValue<number[]>
  x11y11: SpringValue<number[]>
  x12y12: SpringValue<number[]>
  x13y13: SpringValue<number[]>
  opacity: SpringValue<number>
}

export const BlackSharp: React.FC<Props> = ({
  x1y1,
  x2y2,
  x3y3,
  x4y4,
  x5y5,
  x6y6,
  x7y7,
  x8y8,
  x9y9,
  x10y10,
  x11y11,
  x12y12,
  x13y13,
  opacity,
}) => {
  return (
    <animated.g id="black-sharp" opacity={opacity}>
      <animated.path
        className="face front"
        fill="url(#front)"
        d={to([x1y1, x2y2, x3y3, x4y4, x5y5, x6y6, x7y7, x8y8], calculatePath)}
      />
      <animated.path
        className="face left"
        fill="url(#left)"
        d={to([x8y8, x7y7, x9y9, x10y10], calculatePath)}
      />
      <animated.path
        className="face top"
        fill="url(#top)"
        d={to([x1y1, x13y13, x12y12, x11y11], calculatePath)}
      />
      <animated.path
        className="face edge"
        fill="#545454"
        d={to([x1y1, x8y8, x10y10, x11y11], calculatePath)}
      />
    </animated.g>
  )
}
