import React from 'react'
import styled from 'styled-components'

const Container = styled('div')`
  position: relative;
  * {
    pointer-events: none;
    input,
    textarea,
    .child {
      pointer-events: auto;
    }
  }
`

const Scroller = styled('div')`
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  overflow-y: auto;
  scroll-snap-points-y: repeat(calc(--vh, 1vh) * 100);
  scroll-snap-type: y mandatory;
  position: relative;
  pointer-events: auto;

  .child {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    scroll-snap-stop: always;
    font-size: 1.2rem;
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-snap-align: center;
    pointer-events: auto;
  }
`

type Props = {
  page: number
} & React.HTMLAttributes<HTMLDivElement>

export const ScrollElement: React.FC<Props> = ({ page, children, ...restProps }) => {
  return (
    <Container>
      <Scroller {...restProps}>
        {Array.from({ length: page }).map((_, i) => (
          <div className="child" key={i} onClick={e => e.preventDefault()} />
        ))}
      </Scroller>
      {children}
    </Container>
  )
}
