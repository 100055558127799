import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import { mediaQuery } from '../../utils/styled'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

const ClientContainer = styled(animated.div)`
  display: flex;
  flex-wrap: wrap;
  align-items: space-around;
  position: absolute;
  right: 25px;
  bottom: 75px;
  width: calc(100vw - 50px);
  & > div {
    flex-basis: 33.3333%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
  }
  ${mediaQuery('viewport7')`
    bottom: 150px;
    right: 50px;
    width: 400px;
    height: 350px
  `}
  ${mediaQuery('viewport10')`
    bottom: 250px;
    right: 100px;
    width: 530px;
    height: 530px;
  `}
  ${mediaQuery('viewport12')`
    bottom: 100px;
  `}
  ${mediaQuery('viewport16')`
    right: 80px;
    width: calc(50vw - 80px);
    bottom: 220px;
  `}
`

const LeftContainer = styled(animated.div)`
  position: absolute;
  top: 190px;
  right: 25px;
  width: calc(100vw - 50px);
  ${mediaQuery('viewport7')`
    width: 400px;
    top: 150px;
    right: 50px;
  `}
  ${mediaQuery('viewport10')`
    width: 530px;
    top: 250px;
    right: 100px;
  `}
  ${mediaQuery('viewport12')`
    left: 50px;
    bottom: 100px;
    top: unset;
  `}
  ${mediaQuery('viewport16')`
    left: 100px;
    bottom: 220px;
    width: calc(50vw - 100px);
  `}
`

const Title = styled('div')`
  font-weight: 500;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 26px;
  ${mediaQuery('viewport7')`
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 36px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 48px;
    line-height: 50px;
  `}
  ${mediaQuery('viewport12')`
    font-size: 32px;
    line-height: 36px;
  `}
  ${mediaQuery('viewport16')`
    font-size: 48px;
    line-height: 50px;
  `}
`
const Description = styled('div')`
  font-weight: 300;
  text-align: left;
  opacity: 0.5;
  font-size: 16px;
  line-height: 20px;
  ${mediaQuery('viewport7')`
    font-size: 20px;
    line-height: 28px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 24px;
    line-height: 35px;
  `}
`
const CLIENTS = [
  require('../../assets/clients/client-01.svg'),
  require('../../assets/clients/client-02.svg'),
  require('../../assets/clients/client-03.svg'),
  require('../../assets/clients/client-04.svg'),
  require('../../assets/clients/client-05.svg'),
  require('../../assets/clients/client-06.svg'),
  require('../../assets/clients/client-07.svg'),
  // require('../../assets/clients/client-08.svg'),
  require('../../assets/clients/client-09.svg'),
  require('../../assets/clients/client-10.svg'),
  // require('../../assets/clients/client-11.svg'),
  // require('../../assets/clients/client-12.svg'),
]

type Props = {
  step: number
}

export const Clients: React.FC<Props> = ({ step = 4 }) => {
  const { linear } = useSpring({
    linear: step,
    config: animationConfig,
  })

  const { viewport, innerWidth } = useViewport()

  const LEFT_CONTAINER_RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: ['translate(300px, 0px)', 'translate(0px, 0px)', 'translate(-300px, 0px)'],
    },
    viewport7: {
      transform: ['translate(500px, 0px)', 'translate(0px, 0px)', 'translate(0px, -300px)'],
    },
    viewport10: {
      transform: ['translate(600px, 0px)', 'translate(0px, 0px)', 'translate(0px,-400px)'],
    },
    viewport12: {
      transform: ['translate(600px, 0px)', 'translate(0px, 0px)', 'translate(-200px, 0px)'],
    },
    viewport14: {
      transform: ['translate(600px, 0px)', 'translate(0px, 0px)', 'translate(-200px, 0px)'],
    },
    viewport16: {
      transform: ['translate(600px, 0px)', 'translate(0px, 0px)', 'translate(-200px, 0px)'],
    },
  }

  const CLIENTS_CONTAINER_RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: ['translate(300px, 0px)', 'translate(0px, 0px)', 'translate(-300px, 0px)'],
    },
    viewport7: {
      transform: ['translate(500px, 0px)', 'translate(0px, 0px)', 'translate(0px, -300px)'],
    },
    viewport10: {
      transform: ['translate(600px, 0px)', 'translate(0px, 0px)', 'translate(0px, -400px)'],
    },
    viewport12: {
      transform: [
        'translate(600px, 0px)',
        'translate(0px, 0px)',
        `translate(-${innerWidth / 2}px, 0px)`,
      ],
    },
    viewport14: {
      transform: [
        'translate(600px, 0px)',
        'translate(0px, 0px)',
        `translate(-${innerWidth / 2 + 200}px, 0px)`,
      ],
    },
    viewport16: {
      transform: [
        'translate(600px, 0px)',
        'translate(0px, 0px)',
        `translate(-${innerWidth / 2 + 200}px, 0px)`,
      ],
    },
  }

  if (step < 4) {
    return null
  }

  return (
    <React.Fragment>
      <LeftContainer
        style={{
          transform: linear.interpolate([4, 5, 6], LEFT_CONTAINER_RESPONSIVE[viewport].transform),
          opacity: linear.interpolate([4, 5, 6], [0, 1, 0]),
        }}
      >
        <Title>Awesome clients and brands we’ve worked with</Title>
        <Description>
          Our clients come in all shapes and sizes.
          <br />
          They vary from smaller businesses launching a new website or product, to huge enterprise
          processing billions USD in transaction.
        </Description>
      </LeftContainer>
      <ClientContainer
        style={{
          transform: linear.interpolate(
            [4, 5, 6],
            CLIENTS_CONTAINER_RESPONSIVE[viewport].transform,
          ),
          opacity: linear.interpolate([4, 5, 6], [0, 1, 0]),
        }}
      >
        {CLIENTS.map((logo, index) => (
          <div key={`logo-${index}`}>
            <img src={logo} />
          </div>
        ))}
      </ClientContainer>
    </React.Fragment>
  )
}
