import React from 'react'
import styled from 'styled-components'
import 'styled-components/macro'
import { mediaQuery } from '../utils/styled'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 100px);
  form {
    input[type='submit'] {
      padding: 16px 48px;
      border: 1px solid white;
      background: none;
      color: white;
      font-size: 20px;
    }
  }
  .form-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .column {
    flex: 1;
    &:first-child {
      margin: 0 0 24px;
    }
  }
  ${mediaQuery('viewport7')`
    width: 660px;
    form {
      .form-content {
        flex-direction: row;
      }
      .column {
        flex:2;
        &:first-child {
          flex: 1;
          margin: 0 24px 0 0;
        }
      }
    }
  `}
`

const Title = styled('div')`
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  ${mediaQuery('viewport7')`
    font-size: 48px;
    line-height: 34px;
    margin-bottom: 50px;
  `}
`

const FormControl = styled('div')`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  label {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 34px;
    color: #fff;
  }
  textarea,
  input[type='text'] {
    background: transparent;
    border: none;
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 34px;
    color: #fff;
    opacity: 0.8;
    border-bottom: 1px solid transparent;

    &:placeholder {
      font-size: 28px;
      letter-spacing: 0.01em;
      line-height: 34px;
      color: #fff;
      opacity: 0.3;
    }
    &:focus {
      outline: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }
  }
  ${mediaQuery('viewport7')`
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    label {
      font-size: 20px;
      line-height: 34px;
    }
    textarea,
    input[type='text'] {
      margin-top: 12px;
      font-size: 28px;
      line-height: 34px;
      padding: 8px 0;
    }
  `}
`

type Props = {}

export const ContactForm: React.FC<Props> = () => {
  const [form, dispatch] = React.useReducer((store, newValue) => ({ ...store, ...newValue }), {
    name: '',
    phoneNumber: '',
    email: '',
    message: '',
  })
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState()
  const [success, setSuccess] = React.useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setError('')
    setSuccess(false)
    if (!form.name || !form.phoneNumber || !form.email || !form.message) {
      setError('All fields are required!')
      return
    }
    try {
      setLoading(true)
      await fetch(`https://submit-form.com/ogf-uwh6jy3c39KNw3nMX`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      })
      setSuccess(true)
    } catch (e) {
      console.log('Form Error:', e)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Container>
      <Title>Hello Missing Corner</Title>
      {error && (
        <div
          css={`
            color: red;
            margin-bottom: 16px;
          `}
        >
          {error}
        </div>
      )}
      {success && (
        <div
          css={`
            color: green;
            margin-bottom: 16px;
          `}
        >
          Your message was sent successful! We&apos;ll get in touch with you as soon as posible.
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-content">
          <div className="column">
            <FormControl>
              <label>
                My name is
                <input
                  type="text"
                  value={form.name}
                  onChange={e => dispatch({ name: e.target.value })}
                  placeholder="name"
                />
              </label>
            </FormControl>
            <FormControl>
              <label>
                My phone number is
                <input
                  type="text"
                  value={form.phoneNumber}
                  onChange={e => dispatch({ phoneNumber: e.target.value })}
                  placeholder="number"
                />
              </label>
            </FormControl>
            <FormControl>
              <label>
                Reach me at
                <input
                  type="text"
                  value={form.email}
                  onChange={e => dispatch({ email: e.target.value })}
                  placeholder="your@email.com"
                />
              </label>
            </FormControl>
          </div>
          <div className="column">
            <FormControl>
              <label>
                Message
                <textarea
                  value={form.message}
                  onChange={e => dispatch({ message: e.target.value })}
                  placeholder="Start typing here ..."
                />
              </label>
            </FormControl>
          </div>
        </div>
        <input type="submit" value={loading ? 'Loading ...' : 'Submit'} disabled={loading} />
      </form>
    </Container>
  )
}
