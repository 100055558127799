import React from 'react'
import { animated, config, useTrail, useTransition } from 'react-spring'
import styled from 'styled-components'
import { mediaQuery } from '../utils/styled'

const Container = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 900;
`

const Mask = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #282828;
  z-index: -1;
`

const AnimatedMask = animated(Mask)

const OutlineBoxContainer = styled('div')`
  position: fixed;
  bottom: -115px;
  left: -130px;
  transform-origin: 0 100%;
`

const AnimatedOutlineBoxContainer = animated(OutlineBoxContainer)

const Content = styled('div')`
  position: fixed;
  top: calc(50% + 100px);
  right: 0px;
  transform: translateY(-50%);
`

export const UnorderList = styled('ul')`
  counter-reset: li;
  perspective: 1000px;
  li {
    font-weight: 800;
    color: white;
    font-size: 42px;
    text-transform: uppercase;
    text-align: right;
    padding-bottom: 32px;
    position: relative;
    cursor: pointer;
    ${mediaQuery('viewport7')`
      font-size: 90px;
    `}
    ${mediaQuery('viewport10')`
      font-size: 110px;
    `}
    ${mediaQuery('viewport12')`
    `}
    ${mediaQuery('viewport14')`
      font-size: 130px;
    `}
    ${mediaQuery('viewport16')`
      font-size: 160px;
    `}
    
    div {
      transform: rotate(0);
      transition: transform 0.2s ease-in-out;
    }
    &:hover {
    transition: transform 0.2s ease-in-out;
    transform: translateZ(120px) rotateY(-45deg);
      div {
       
        display: block;
        
        span {
          color: #282828;
        }
      }
    }
    svg {
      text {
        fill: white;
        stroke: black;
        stroke-width: 1px;
        font-weight: 900;
      }
    }
    &:hover {
      svg {
        text {
          fill: black;
          stroke: white;
        }
      }
    }
    span {
      position: relative;
      padding-left: 32px;
      color: white;
      transition: color 0.4s ease-in-out;
      ${mediaQuery('medium')`
        text-shadow: 1px 1px 0px white, -1px -1px 0px white, 1px -1px 0px white, -1px 1px 0px white;
      `}
      &:before {
        font-family: 'DIN Pro';
        counter-increment: li;
        content: '0' counter(li);
        color: white;
        font-size: 16px;
        ${mediaQuery('medium')`
          font-size:32px;
        `}
        position: absolute;
        font-weight: 300;
        top: 0;
        left: -48px;
      }
      &:after {
        content: '';
        width: 2px;
        height: calc(100% - 8px);
        ${mediaQuery('medium')`
          height: calc(100% - 32px);
        `}
        background: white;
        transform: rotate(10deg);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`

type Props = {
  active?: boolean
  items?: React.HTMLAttributes<HTMLLIElement>[]
}

export const Menu = ({ active = false, items = [] }: Props) => {
  const transitions = useTransition(active, {
    from: { height: '0vh', transform: 'scale(0)' },
    enter: { height: '110vh', transform: 'scale(1)' },
    leave: { height: '0vh', transform: 'scale(0)' },
  })

  const trails = useTrail(items.length, {
    linear: active ? 1 : 0,
    from: { linear: 0 },
    delay: active ? 100 : 0,
    config: active ? config.default : { ...config.default, tension: 320 },
  })

  return (
    <React.Fragment>
      {transitions(
        (style, item) =>
          item && (
            <Container>
              <AnimatedMask style={{ height: style.height }} />
              <AnimatedOutlineBoxContainer
                style={{
                  transform: style.transform,
                }}
              >
                <img src={require('../assets/outline-box.svg')} />
              </AnimatedOutlineBoxContainer>
              <Content>
                <UnorderList>
                  {trails.length > 0 &&
                    trails.map(({ linear }, index) => {
                      const { children, ...restLiProps } = items[index]
                      return (
                        <animated.li
                          style={{
                            transform: linear.interpolate({
                              range: [0, 1],
                              output: [
                                'translateX(100px) rotate(5deg)',
                                'translateX(-100px) rotate(10deg)',
                              ],
                            }),
                            opacity: linear.interpolate({
                              range: active ? [0, 1] : [0, 0.5, 1],
                              output: active ? [0, 1] : [0, 0.7, 1],
                            }),
                          }}
                          key={`list-${index}`}
                          {...restLiProps}
                        >
                          <div>
                            <span>{children}</span>
                          </div>
                        </animated.li>
                      )
                    })}
                </UnorderList>
              </Content>
            </Container>
          ),
      )}
    </React.Fragment>
  )
}
