import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components/macro'
import { mediaQuery } from '../../utils/styled'
import { ViewportKeys, useViewport } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'

const Container = styled(animated.div)`
  position: absolute;
  width: calc(100vw - 100px);
  top: 190px;
  left: 50px;
  ${mediaQuery('viewport7')`
    width: calc(100vw - 300px);
    top: 40%;
    left: 200px;
  `}
  ${mediaQuery('viewport10')`
    width: calc(100vw - 350px);
    left: 200px;
  `}
  ${mediaQuery('viewport12')`
    width: calc(50vw - 100px);
    left: 150px;
    top: calc(50% + 40px);
  `}
  ${mediaQuery('viewport16')`
    left: 200px;
    width: calc(50vw - 200px);
  `}
`

const Review = styled('div')`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  ${mediaQuery('viewport7')`
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 32px;
    line-height: 40px;
  `}
  ${mediaQuery('viewport12')`
    font-size: 24px;
    line-height: 28px;
  `}
  ${mediaQuery('viewport14')`
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  `}
`

const Author = styled('div')`
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  opacity: 0.5;
  text-align: right;
  strong {
    font-weight: bold;
  }
  ${mediaQuery('viewport10')`
    font-size: 24px;
  `}
`

type Props = {
  step: number
}

export const Testimonial: React.FC<Props> = ({ step }) => {
  const { linear } = useSpring({
    linear: step,
    config: animationConfig,
  })

  const { viewport, innerWidth, innerHeight } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: [
        `translate(${innerWidth + 50}px, ${innerHeight / 2}px)`,
        `translate(0px, 0px)`,
        `translate(0px, -50px)`,
      ],
    },
    viewport7: {
      transform: ['translate(500px, -50%)', 'translate(0px, -50%)', 'translate(0px, -50%)'],
    },
    viewport10: {
      transform: ['translate(700px, -50%)', 'translate(0px, -50%)', 'translate(0px, -50%)'],
    },
    viewport12: {
      transform: ['translate(500px, -50%)', 'translate(0px, -50%)', 'translate(0px, -50%)'],
    },
    viewport14: {
      transform: ['translate(700px, -50%)', 'translate(0px, -50%)', 'translate(0px, -50%)'],
    },
    viewport16: {
      transform: ['translate(700px, -50%)', 'translate(0px, -50%)', 'translate(0px, -50%)'],
    },
  }

  if (step < 5) {
    return null
  }

  return (
    <Container
      style={{
        opacity: linear.interpolate([5, 6, 7], [0, 1, 0]),
        transform: linear.interpolate([5, 6, 7], RESPONSIVE[viewport].transform),
      }}
    >
      <Review>
        Missing Corner stands for the incendiary fusion of form and function.
        <br />
        You can see and feel the difference. They make apps that excite your eyes and set your
        thumbs on fire.
      </Review>
      <Author>
        - Our Client's <strong>Testimonial</strong>
      </Author>
    </Container>
  )
}
