import React from 'react'
import { useSpring } from 'react-spring'
import { useViewport, ViewportKeys } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'
import { Container } from './AppDev'
import { Description, Title } from './Enterprise'

type Props = {
  step: number
}

export const UIUX: React.FC<Props> = ({ step = 3 }) => {
  const { viewport, innerWidth } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: { transform: ['translate(0px, 0px)'] },
    viewport7: {
      transform: [
        `translate(${innerWidth}px, 0px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth}px, 0px)`,
      ],
    },
    viewport10: {
      transform: [
        `translate(${innerWidth}px, 0px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth}px, 0px)`,
      ],
    },
    viewport12: {
      transform: [
        `translate(${innerWidth}px, -50%)`,
        `translate(0px, -50%)`,
        `translate(-${innerWidth}px, -50%)`,
      ],
    },
    viewport14: {
      transform: [
        `translate(${innerWidth}px, -210px)`,
        `translate(0px, -210px)`,
        `translate(0px, -510px)`,
      ],
    },
    viewport16: {
      transform: [
        `translate(${innerWidth}px, -210px)`,
        `translate(0px, -210px)`,
        `translate(0px, -510px)`,
      ],
    },
  }

  const [style, setSpring] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: RESPONSIVE[viewport].transform[0],
    },
    config: animationConfig,
  }))

  React.useEffect(() => {
    switch (step) {
      case 3:
        setSpring({
          opacity: 0,
          transform: RESPONSIVE[viewport].transform[0],
        })
        return
      case 4:
        setSpring({
          opacity: 1,
          transform: RESPONSIVE[viewport].transform[1],
        })
        return
      case 5:
        setSpring({
          opacity: 0,
          transform: RESPONSIVE[viewport].transform[2],
        })
        return
      default:
        step < 3 &&
          setSpring({
            opacity: 0,
            transform: RESPONSIVE[viewport].transform[0],
          })

        step > 5 &&
          setSpring({
            opacity: 0,
            transform: RESPONSIVE[viewport].transform[2],
          })

        return
    }
  }, [RESPONSIVE, setSpring, step, viewport])

  return (
    <Container style={style}>
      <Title>Design UI/UX</Title>
      <Description>
        The best designs instill confidence in your product and brand. They inspire users to
        download, engage, and evangelize. Our digitally native designers create uniquely beautiful
        and functional work grounded in results-oriented best practices.
        <br />
        <br />
        — Logo &amp; Branding
        <br />
        — User Experience
        <br />
        — Information Architecture
        <br />
        — Prototyping
        <br />— Mobile &amp; Web Design
      </Description>
    </Container>
  )
}
