import React from 'react'
import { ScrollElement } from '../ScrollElement'
import { BelieveIn } from './BelieveIn'
import { BlackObject } from './BlackObject'
import { Clients } from './Clients'
import { Contact } from './Contact'
import { DevAndDesign } from './DevAndDesign'
import { OutlineObject } from './OutlineObject'
import { Slider } from './Slider'
import { Testimonial } from './Testimonial'
import { ThinkOutside } from './ThinkOutside'

type Props = {
  setLogoBlack(isBlack: boolean): void
  setMenuBlack(isBlack: boolean): void
  setStep: React.Dispatch<React.SetStateAction<number>>
  step: number
}

export const Corner: React.FC<Props> = ({ setLogoBlack, setMenuBlack, setStep, step }) => {
  React.useEffect(() => {
    switch (step) {
      case 0:
        setMenuBlack(true)
        setLogoBlack(true)
        return
      case 1:
        setMenuBlack(false)
        setLogoBlack(false)
        return
      case 2:
        setMenuBlack(false)
        setLogoBlack(true)
        return
      case 3:
        setMenuBlack(false)
        setLogoBlack(false)
        return
      case 4:
        setMenuBlack(true)
        setLogoBlack(true)
        return
      case 5:
        setMenuBlack(false)
        setLogoBlack(true)
        return
      case 6:
        setMenuBlack(false)
        setLogoBlack(false)
        return
      default:
        setMenuBlack(true)
        setLogoBlack(true)
    }
  }, [setLogoBlack, setMenuBlack, step])
  return (
    <ScrollElement
      page={7}
      onScroll={e => {
        const top = (e.target as HTMLDivElement).scrollTop
        setStep(Math.round((top / window.innerHeight) * 100) / 100)
      }}
    >
      <ThinkOutside active={step === 0} step={step} />
      <Slider page={step} maxPage={7} isBlack={step === 0 || step === 4 || step === 5} />
      <DevAndDesign step={step} />
      <BelieveIn step={step + 1} />
      <BlackObject step={step + 1} />
      <OutlineObject step={step + 1} />
      <Clients step={step + 1} />
      <Testimonial step={step + 1} />
      <Contact step={step + 1} />
    </ScrollElement>
  )
}
