import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import 'styled-components/macro'
import { useViewport, ViewportKeys } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'
import { mediaQuery } from '../../utils/styled'

export const Container = styled(animated.div)`
  width: calc(100% - 50px);
  position: absolute;
  top: calc(50% + 20px);
  left: 25px;
  z-index: 30;
  ${mediaQuery('viewport7')`
    top: calc(50% + 80px);
    left: 50px;
    width: calc(100vw - 100px);
    height: 50vh;
  `}
  ${mediaQuery('viewport10')`
    left: 80px;
    top: calc(50% + 120px);
    width: calc(100% - 160px);
  `}
  ${mediaQuery('viewport12')`
    left: 50px;
    top: 50%;
    width: calc(50vw - 50px);
  `}
  ${mediaQuery('viewport14')`
    left: 100px;
    top: calc(50% - 20px);
    width: calc(50vw - 100px);
  `}
`

export const Title = styled('div')`
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 8px;
  ${mediaQuery('viewport7')`
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  `}
  ${mediaQuery('viewport10')`
    font-size: 48px;
    line-height: 50px;
  `}
  ${mediaQuery('viewport12')`
    font-size: 32px;
    line-height: 40px;
  `}
  ${mediaQuery('viewport14')`
    font-size: 48px;
    line-height: 50px;
  `}
`
export const Description = styled('div')`
  font-weight: 300;
  color: #fff;
  opacity: 0.5;
  font-size: 14px;
  line-height: 18px;
  p {
  padding-bottom: 10px;
  }
  ul {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    li {
      flex-basis: 50%;
    }
  }
  ${mediaQuery('viewport7')`
    font-size:18px;
    line-height: 24px;
    ul {
      margin-top: 16px;
      li {
        flex-basis: 100%;
      }
    }
  `}
  ${mediaQuery('viewport10')`
    font-size:24px;
    line-height: 35px;
  `}
  ${mediaQuery('viewport12')`
    font-size: 18px;
    line-height: 24px;
  `}
  ${mediaQuery('viewport14')`
    font-size: 24px;
    line-height: 35px;
  `}
`

type Props = {
  step: number
}

export const Enterprise: React.FC<Props> = ({ step = 1 }) => {
  const { viewport, innerWidth } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: {
      transform: ['translate(0px, 0px)'],
    },
    viewport7: {
      transform: ['translate(0px, 0px)', 'translate(0px, 0px)', `translate(-${innerWidth}px, 0px)`],
    },
    viewport10: {
      transform: ['translate(0px, 0px)', 'translate(0px, 0px)', `translate(-${innerWidth}px, 0px)`],
    },
    viewport12: {
      transform: [
        'translate(0px, -50%)',
        'translate(0px, -50%)',
        `translate(-${innerWidth}px, -50%)`,
      ],
    },
    viewport14: {
      transform: [
        'translate(0px, -50%)',
        'translate(0px, -50%)',
        `translate(-${innerWidth}px, -50%)`,
      ],
    },
    viewport16: {
      transform: [
        'translate(0px, -50%)',
        'translate(0px, -50%)',
        `translate(-${innerWidth}px, -50%)`,
      ],
    },
  }

  const [style, setSpring] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: RESPONSIVE[viewport].transform[0],
    },
    config: animationConfig,
  }))

  React.useEffect(() => {
    switch (step) {
      case 0:
        setSpring({ opacity: 0, transform: RESPONSIVE[viewport].transform[0] })
        return
      case 1:
        setSpring({ opacity: 1, transform: RESPONSIVE[viewport].transform[1] })
        return
      case 2:
        setSpring({ opacity: 0, transform: RESPONSIVE[viewport].transform[2] })
        return
      default:
        step > 2 && setSpring({ opacity: 0, transform: RESPONSIVE[viewport].transform[2] })
        return
    }
  }, [RESPONSIVE, setSpring, step, viewport])

  return (
    <Container style={style}>
      <Title>
        Enterprise Software
        <br />& Digital Transformation
      </Title>
      <Description>
        Our enterprise products have helped companies generating over $100M within a single year,
        it’s no surprise we’re constantly in high demand as one of the best enterprise software
        developer. Our deep knowledge in Big Data and Artificial Intelligence in conjunction with
        awesome UI/UX has allowed us to digitally transform some of the world's largest companies in
        unimaginable ways.
        <ul>
          <li>- Enterprise Software Development </li>
          <li>- Customer Experience & Marketing Automation</li>
          <li>- Analytics, Big Data & Data Platform</li>
          <li>- Machine Learning & AI</li>
          <li>- Digital Strategy & Consulting</li>
        </ul>
      </Description>
    </Container>
  )
}
