import React from 'react'
import styled from 'styled-components'
import { Logo } from './Logo'
import { BurgerButton } from './BurgerButton'
import { mediaQuery } from '../utils/styled'

const Container = styled('div')`
  position: fixed;
  top: 25px;
  left: 25px;
  width: calc(100vw - 50px);
  z-index: 999;
  ${mediaQuery('viewport7')`
    top: 48px;
    left: 48px;
    width: calc(100vw - 96px);
  `}
`

const Content = styled('div')`
  display: flex;
  justify-content: space-between;
`

type Props = {
  isLogoBlack?: boolean
  isBurgerHidden?: boolean
  isBurgerBlack?: boolean
  isBurgerActive?: boolean
  onBurgerClick?(): void
  onLogoClick?(): void
}

export const Header: React.FC<Props> = ({
  isLogoBlack,
  isBurgerHidden,
  isBurgerActive,
  isBurgerBlack,
  onBurgerClick,
  onLogoClick,
}) => {
  return (
    <Container>
      <Content>
        <Logo isBlack={isLogoBlack} onClick={onLogoClick} />
        {!isBurgerHidden && (
          <BurgerButton isBlack={isBurgerBlack} active={isBurgerActive} onClick={onBurgerClick} />
        )}
      </Content>
    </Container>
  )
}
