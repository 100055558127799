import React from 'react'
import { useSpring, animated } from 'react-spring'
import { animationConfig } from '../../utils/config'

type Props = {
  show?: boolean
}

export const MiniBoxs: React.FC<Props> = ({ show = false }) => {
  const [boxLeft, setBoxLeft] = useSpring(() => ({
    from: {
      transform: 'translate(-200px, 0px)',
      opacity: 0,
    },
    config: animationConfig,
  }))
  const [boxTop, setBoxTop] = useSpring(() => ({
    from: {
      transform: 'translate(0px, -200px)',
      opacity: 0,
    },
    config: animationConfig,
  }))

  React.useEffect(() => {
    if (show) {
      setBoxLeft({ transform: 'translate(0px, 0px)', opacity: 1, delay: 500 })
      setBoxTop({ transform: 'translate(0px, 0px)', opacity: 1, delay: 500 })
      return
    }
    setBoxLeft({ transform: 'translate(-200px, 0px)', opacity: 0, delay: 0 })
    setBoxTop({ transform: 'translate(0px, -200px)', opacity: 0, delay: 0 })
  }, [show])

  return (
    <g id="mini-boxs">
      <animated.g style={boxLeft}>
        <path
          d="M2845.1 1522.114l-118 13.335v126.771l116.284-21.022z"
          transform="translate(-2593.745 -1031.557)"
          fill="url(#front)"
        />
        <path
          d="M2656.1 1240.176v128.232l-45.242-33.216v-119.681z"
          transform="translate(-2522.744 -737.899)"
          fill="url(#left)"
        />
        <path
          d="M2774.172 1205.457l-64.258-24.51-99.134 11.47 45.3 26.4z"
          transform="translate(-2522.744 -714.899)"
          fill="url(#top)"
        />
      </animated.g>
      <animated.g style={boxTop}>
        <path
          d="M2845.1 1522.114l-118 13.335v126.771l116.284-21.022z"
          transform="translate(-2347.745 -1392.557)"
          fill="url(#front)"
        />
        <path
          d="M2656.1 1240.176v128.232l-57.626-21.937v-119.682z"
          transform="translate(-2276.744 -1098.899)"
          fill="url(#left)"
        />
        <path
          d="M2774.172 1205.457l-76.792-9.659-98.885 8.006 57.587 15.017z"
          transform="translate(-2276.744 -1075.899)"
          fill="url(#top)"
        />
      </animated.g>
    </g>
  )
}
