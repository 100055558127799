import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import { useViewport, ViewportKeys } from '../../contexts/ViewportContext'
import { animationConfig } from '../../utils/config'
import { mediaQuery } from '../../utils/styled'
import { Description, Title } from './Enterprise'

export const Container = styled(animated.div)`
  position: absolute;
  top: calc(50% + 20px);
  left: 25px;
  width: calc(100vw - 50px);
  ${mediaQuery('viewport7')`
    top: calc(50% + 80px);
    left: 50px;
    width: calc(100vw - 100px);
    z-index: 23;
  `}
  ${mediaQuery('viewport10')`
    top: calc(50% + 120px);
    left: 80px;
    width: calc(100vw - 160px);
  `}
  ${mediaQuery('viewport12')`
    top: 50%;
    left: unset;
    right: 50px;
    width: calc(50vw - 50px);
  `}
  ${mediaQuery('viewport14')`
    right: 80px;
    width: calc(50vw - 80px);
    top: calc(50% - 20px);
  `}
`

type Props = {
  step: number
}

export const AppDev: React.FC<Props> = ({ step = 1 }) => {
  const { viewport, innerWidth } = useViewport()

  const RESPONSIVE: Record<ViewportKeys, { transform: string[] }> = {
    viewport3: { transform: ['translate(0px, 0px)'] },
    viewport7: {
      transform: [
        `translate(${innerWidth}px, 0px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth}px, 0px)`,
      ],
    },
    viewport10: {
      transform: [
        `translate(${innerWidth}px, 0px)`,
        `translate(0px, 0px)`,
        `translate(-${innerWidth}px, 0px)`,
      ],
    },
    viewport12: {
      transform: [
        `translate(${innerWidth}px, -175px)`,
        `translate(0px, -175px)`,
        `translate(0px, -475px)`,
      ],
    },
    viewport14: {
      transform: [
        `translate(${innerWidth}px, -210px)`,
        `translate(0px, -210px)`,
        `translate(0px, -510px)`,
      ],
    },
    viewport16: {
      transform: [
        `translate(${innerWidth}px, -210px)`,
        `translate(0px, -210px)`,
        `translate(0px, -510px)`,
      ],
    },
  }

  const [style, setSpring] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: RESPONSIVE[viewport].transform[0],
    },
    config: animationConfig,
  }))

  React.useEffect(() => {
    switch (step) {
      case 1:
        setSpring({
          opacity: 0,
          transform: RESPONSIVE[viewport].transform[0],
        })
        return
      case 2:
        setSpring({
          opacity: 1,
          transform: RESPONSIVE[viewport].transform[1],
        })
        return
      case 3:
        setSpring({
          opacity: 0,
          transform: RESPONSIVE[viewport].transform[2],
        })
        return
      default:
        step < 1 &&
          setSpring({
            opacity: 0,
            transform: RESPONSIVE[viewport].transform[0],
          })
        step > 3 &&
          setSpring({
            opacity: 0,
            transform: RESPONSIVE[viewport].transform[2],
          })
        return
    }
  }, [RESPONSIVE, setSpring, step, viewport])

  return (
    <Container style={style}>
      <Title>App Development</Title>
      <Description>
        <p>
          Whether you’re looking to create amazing user experiences, streamline operations, or get
          an edge over competitors, Missing Corner offers the mobile app development expertise to
          help you get there. Leveraging our technical talents and strategic insights, we deliver
          rich experiences that spur brand growth.
        </p>

        <ul>
          <li>- iOS Development</li>
          <li>- Android Development</li>
          <li>- React Native Development</li>
          <li>- Open API, GraphQL</li>
          <li>- Cloud, DevOps</li>
        </ul>
      </Description>
    </Container>
  )
}
