import React from 'react'
import { animated, to, useSpring } from 'react-spring'
import styled from 'styled-components'
import { Contact } from '../components/Contact'
import { Corner } from '../components/Corner/Corner'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Menu, UnorderList as BaseUnorderList } from '../components/Menu'
import { Service } from '../components/Service/Service'
import { AnimatedSVGs } from '../components/svgs/AnimatedSVGs'
import { ViewportProvider } from '../contexts/ViewportContext'

const Container = styled(animated.div)`
  width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`

const UnorderList = styled(BaseUnorderList)`
  li {
    transform: rotateY(-45deg);
  }
`

enum ROUTES {
  HomeIntro = 'ROUTE_HOME_INTRO',
  Corner = 'ROUTE_CORNER',
  Service = 'ROUTE_SERVICE',
  Contact = 'ROUTE_CONTACT',
}

const IndexPage = () => {
  const [route, setRoute] = React.useState(ROUTES.HomeIntro)
  const [showMenu, setShowMenu] = React.useState(false)
  const [isMenuBlack, setMenuBlack] = React.useState(false)
  const [isLogoBlack, setLogoBlack] = React.useState(false)
  const [step, setStep] = React.useState(0)

  const MENU_ITEMS = [
    {
      children: 'About',
      onClick() {
        setRoute(ROUTES.Corner)
        setShowMenu(false)
      },
    },
    {
      children: 'Service',
      onClick() {
        setRoute(ROUTES.Service)
        setShowMenu(false)
      },
    },
    {
      children: 'Contact',
      onClick() {
        setRoute(ROUTES.Contact)
        setShowMenu(false)
      },
    },
  ]

  const [spring, setSpring] = useSpring(() => ({ background: 1 }))
  const [parallax, setParallax] = useSpring(() => ({
    x: 0,
    y: 0,
  }))

  React.useEffect(() => {
    switch (route) {
      case ROUTES.HomeIntro:
        setMenuBlack(false)
        setLogoBlack(false)
        setSpring({ background: 0 })
        setStep(0)
        return
      case ROUTES.Corner:
        setMenuBlack(true)
        setLogoBlack(true)
        setSpring({ background: 1 })
        setStep(0)
        setParallax({ x: 0, y: 0 })
        return
      case ROUTES.Service:
        setMenuBlack(false)
        setLogoBlack(false)
        setSpring({ background: 0 })
        setStep(0)
        setParallax({ x: 0, y: 0 })
        return
      case ROUTES.Contact:
        setMenuBlack(false)
        setLogoBlack(false)
        setStep(0)
        return
      default:
        return
    }
  }, [route, setParallax, setSpring])

  React.useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const { clientX: x, clientY: y } = event
      setParallax({ x: x - window.innerWidth / 2, y: y - window.innerHeight / 2 })
    }
    if (route === ROUTES.HomeIntro) {
      document.addEventListener('mousemove', handleMouseMove)
    }
    document.body.style.overflow = 'hidden'
    return () => {
      if (route === ROUTES.HomeIntro) {
        document.removeEventListener('mousemove', handleMouseMove)
      }
      document.body.style.overflow = ''
    }
  }, [route, setParallax])

  return (
    <ViewportProvider>
      <Container style={{ background: spring.background.interpolate([1, 0], ['#fff', '#000']) }}>
        <Header
          isLogoBlack={showMenu ? false : isLogoBlack}
          isBurgerBlack={showMenu ? false : isMenuBlack}
          isBurgerActive={!showMenu}
          onBurgerClick={() => {
            setShowMenu(prev => !prev)
          }}
          onLogoClick={() => {
            setRoute(ROUTES.HomeIntro)
            setShowMenu(false)
          }}
        />
        <Menu active={showMenu} items={MENU_ITEMS} />
        <animated.div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10,
            width: '100vw',
            pointerEvents: 'none',
            transform: to(
              [parallax.x, parallax.y],
              (x, y) => `translate3d(${x / 12}px, ${y / 12}px, 0)`,
            ),
          }}
        >
          <AnimatedSVGs step={step} route={route} />
        </animated.div>

        {route === ROUTES.HomeIntro && (
          <React.Fragment>
            <animated.div
              style={{
                zIndex: 11,
                transform: to(
                  [parallax.x, parallax.y],
                  (x, y) => `translate3d(${x / 40}px, ${y / 40}px, 0)`,
                ),
              }}
            >
              <UnorderList>
                {MENU_ITEMS.map(({ children, ...itemProps }, index) => (
                  <li key={`item-${index}`} {...itemProps}>
                    <div>
                      <span>{children}</span>
                    </div>
                  </li>
                ))}
              </UnorderList>
            </animated.div>
            <Footer />
          </React.Fragment>
        )}

        {route === ROUTES.Corner && (
          <Corner
            setLogoBlack={setLogoBlack}
            setMenuBlack={setMenuBlack}
            step={step}
            setStep={setStep}
          />
        )}
        {route === ROUTES.Service && <Service step={step} setStep={setStep} />}
        {route === ROUTES.Contact && <Contact />}
      </Container>
    </ViewportProvider>
  )
}

export default IndexPage
