import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { mediaQuery } from '../../utils/styled'

const Container = styled('div')`
  display: flex;
  height: 3px;
  position: absolute;
  bottom: 50px;
  left: 25px;
  width: calc(100vw - 50px);
  z-index: 400;
  ${mediaQuery('viewport7')`
    left: 50px;
    width: 330px;
  `}
  ${mediaQuery('viewport10')`
    left: 100px;
    width: 400px;
  `}
  ${mediaQuery('viewport12')`
    left: 50px;
    width: 400px;
  `}
`

const Slide = styled('div')`
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background: #ddd;
`

const Bar = styled(animated.div)`
  position: absolute;
  top: 1px;
  left: 0;
  height: 3px;
`

type Props = {
  page: number
  maxPage: number
  isBlack?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const Slider: React.FC<Props> = ({ page, maxPage, isBlack, ...restDivProps }) => {
  const spring = useSpring({ page, black: isBlack ? 1 : 0 })
  return (
    <Container {...restDivProps}>
      <Slide />
      <Bar
        style={{
          width: `calc(100% / ${maxPage})`,
          background: spring.black.interpolate({ range: [0, 1], output: ['#DDDDDD', '#282828'] }),
          transform: spring.page.interpolate({
            range: [0, maxPage - 1],
            output: ['translateX(0%)', `translateX(${maxPage - 1}00%)`],
          }),
        }}
      />
    </Container>
  )
}
