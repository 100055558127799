import React from 'react'
import { animated, useTransition } from 'react-spring'

type Props = {
  show?: boolean
  step: number
}

export const Watch: React.FC<Props> = ({ show = false, step }) => {
  const transitions = useTransition(show, {
    from: {
      opacity: 0,
      transform: 'translate(0px, 0px)',
    },
    enter: {
      opacity: 1,
      transform: 'translate(0px, 0px)',
    },
    leave: {
      opacity: 0,
      transform: step === 2 ? 'translate(200px, 0px)' : 'translate(0px, -200px)',
    },
  })
  return (
    <React.Fragment>
      {transitions(
        (style, item) =>
          item && (
            <animated.g id="watch" style={style}>
              <path
                d="M2502.952 2815.5s20.187 23.342 60.2 22.727c40.8.784 84.764-24.59 118.853-67.436 17.18-18.572 21.838-43.553 23.105-63.836 4-63.983-74.2 36.946-74.2 36.946l-104.919 65.3-20.747-10.014z"
                transform="translate(-2042.273 -2296.618)"
                fill="url(#watch-a)"
              />
              <path
                d="M2510.535 2712.076l11.325 7.6s51.037-66.5 93.725-59.045 47.306 45.508 47.306 65.822-.123 58.652-.123 58.652l10.6-18.418s.4-26.681.4-45.618-3.888-30.556-6.6-36.538c-7.944-20.155-30.708-42.754-68.349-36.38-45.084 7.633-88.284 63.925-88.284 63.925z"
                transform="translate(-2011.802 -2387.739)"
                fill="url(#watch-c)"
              />
              <path
                d="M2542.14 2651.14l-.181 107.26 46.653 17.115s.086-37.022.086-49.476 2.125-34.529-10.841-54.105-35.717-20.794-35.717-20.794z"
                transform="translate(-1937.723 -2378.104)"
                fill="url(#watch-d)"
              />
              <path
                d="M2500.939 2770.263s34.177 39.141 84.81 18.693 77.968-51.075 96.048-81.422-112.856 27.418-112.856 27.418-36.731 24.965-47.93 31.895-20.072 3.416-20.072 3.416z"
                transform="translate(-2034.424 -2265.935)"
                fill="url(#watch-e)"
              />
              <path
                d="M2572.079 2842.546l4.068 22.252 19.588 13.593c14.026 3.758 25.87 3.273 35.364-3.527 15.822-9.092 34.221-26.372 44.137-43.221 17.529-29.691 32.7-54.2 32.7-54.2s.129-10.217 0-43.795-15.671-62.37-29.824-70.549-38.151-14.405-72.579 2.372c-15.074 6.878-53.112 40.513-70.878 63.438a359.436 359.436 0 00-29.639 45.158l-6.373-16.364s57.917-94.191 95.115-105.454c30.576-9.258 79.777-17.236 102.065 5.653 13.428 13.79 25.419 43.389 25.419 80.783v41.89s-12.536 21.967-27.818 48.367-51.839 77.022-91.506 62.775c-9.724-3.493-19.9-9.029-26.532-18.276a34.149 34.149 0 01-3.307-30.895z"
                transform="translate(-2039.833 -2397.292)"
                fill="url(#watch-f)"
              />
              <path
                d="M2546.661 2789.207s30.365-57.733 86.266-96.225c55.376-37.454 100.068-14.173 100.068-14.173l-78.472-46.976s-44.484-7.8-90 20.622-86.032 100.746-86.032 100.746z"
                transform="translate(-2087.352 -2425.927)"
                fill="url(#watch-c)"
              />
              <path
                d="M2462.121 2698.89c.786-10.57 3.6-11.362 12.664-18.848s15.188-12.077 23.219-7.5c7.728 4.4 49.1 26.595 60.229 33.687s14.095 25.325 14.095 36.577v112.21c0 .021.079 19.232-4.145 22.416s-6 5.3-15.8 9.686c-7.139 1.639-16.818-.412-23.652-3.249-15.711-6.521-53.408-29.562-57.751-33.522-7.038-6.419-8.878-12.968-8.878-23.7-.002-34.537.019-113.918.019-127.757z"
                transform="translate(-2105.832 -2352.104)"
                fill="url(#watch-f)"
              />
              <path
                d="M2468.1 2689.091c.03-20.339 3.873-22.258 13.631-16.7 7.728 4.4 51.545 29.128 62.674 36.22s10.737 25.325 10.737 36.577v110.422c0 9.791-7.392 16.058-23.1 9.536s-50.852-24.317-55.194-28.277c-7.037-6.419-8.878-12.969-8.878-23.7-.002-34.533.101-103.74.13-124.078z"
                transform="translate(-2112.148 -2334.345)"
                fill="url(#watch-g)"
              />
              <path
                d="M2657.543 2724.484s-34.293 58.969-45.548 74-23.417 24.4-37.3 28.83-30.636-1.891-30.636-1.891-9.674-3.936-16.34-9.751-12.959-21.787-.542-34.416 44.952-50.755 44.952-50.755v-28.257c0-28.725 21.406-36.817 21.406-36.817v14.3s-10.057 6.538-10.057 21.515v19.739l56.385 20.538 10.929-18.165"
                transform="translate(-1989.31 -2344.424)"
                fill="url(#watch-h)"
              />
            </animated.g>
          ),
      )}
    </React.Fragment>
  )
}
