import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

const SVG = styled('svg')`
  cursor: pointer;
`

type Props = {
  active?: boolean
  isBlack?: boolean
} & React.HTMLAttributes<SVGSVGElement>

export const BurgerButton: React.FC<Props> = ({
  active = false,
  isBlack = false,
  ...restProps
}) => {
  const { linearState } = useSpring({ linearState: active ? 0 : 1 })
  const { linearColor } = useSpring({ linearColor: isBlack ? 1 : 0 })
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <g transform="translate(-196 -56)">
        <animated.rect
          width="20"
          height="2"
          transform={linearState.interpolate({
            range: [1, 0],
            output: ['translate(201.636 60.222) rotate(45)', 'translate(198 76) rotate(0)'],
          })}
          fill={linearColor.interpolate({
            range: [1, 0],
            output: ['black', 'white'],
          })}
        />
        <animated.rect
          width={linearState.interpolate({
            range: [1, 0],
            output: [0, 20],
          })}
          height="2"
          transform={linearState.interpolate({
            range: [1, 0],
            output: ['translate(217 67)', 'translate(198 67)'],
          })}
          fill={linearColor.interpolate({
            range: [1, 0],
            output: ['black', 'white'],
          })}
          opacity={linearState.interpolate({
            range: [1, 0],
            output: [0, 1],
          })}
        />
        <animated.rect
          width={linearState.interpolate({
            range: [1, 0],
            output: [20, 10],
          })}
          height="2"
          transform={linearState.interpolate({
            range: [1, 0],
            output: ['translate(200.222 74.364) rotate(-45)', 'translate(208 58) rotate(0)'],
          })}
          fill={linearColor.interpolate({
            range: [1, 0],
            output: ['black', 'white'],
          })}
        />
      </g>
    </SVG>
  )
}
