// @ts-ignore
import { interpolate } from 'flubber'
export const animationConfig = { mass: 1, tension: 120, friction: 30 }

export const TRIANGLE_QUOTE = interpolate(
  'M0 0h207l-98.438 182L0 0',
  'M141.569 116.856l-29.5 25.319v-25.319H65.005V39.999h76.569',
  {
    maxSegmentLength: 1,
  },
)
